import { Component, OnInit, HostListener } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material';
import { DashboardService } from '../../../../services/dashboard.service';
// import { YearMonthService } from '../../../../services/year-month.service';
// import { TokenService } from '../../../../services/token.service';
import { ClientService } from '../../../../services/client.service';
// import { CountryService } from '../../../../services/country.service';
// import { Router } from '@angular/router';
import { Observable } from 'rxjs';
// import { Client, Country } from '../../../../shared/models/api.model';
import { SlugifyPipe } from '../../../../shared/pipes/slugify.pipe';
import { TeamService } from 'src/app/services/team.service';
import { ComponentCanDeactivate } from 'src/app/shared/models/app.model';
import { Router } from '@angular/router';
// import { SlicePipe } from '@angular/common';
// import { map } from 'rxjs/operators';

@Component({
  selector: 'app-create-dashboard',
  templateUrl: './create-dashboard.component.html',
  styleUrls: ['./create-dashboard.component.css']
})
export class CreateDashboardComponent implements OnInit {

  isForm = true;

  @HostListener('window:beforeunload')
  canDeactivate(): boolean {
    return !this.isForm;
    // if(this.isPageRefresh()) {
    //   return !this.isForm;
    // }
  }

  // isPageRefresh(){
  //   return !this.router.navigated;
  //   // console.log("ALPHA")
  // } 

  pageTitle = "Add Dashboard";
  // months;
  // years;
  // clients$: Observable<Client>;
  // countries$: Observable<Country>;
  clients: any = [];
  countries: any = [];
  teams: any = [];
  countryTeams: any = [];
  dashboardForm: FormGroup;

  clientId: number;

  months: any = [
    {id:1, name:'Jan'},
    {id:2, name:'Feb'},
    {id:3, name:'Mar'},
    {id:4, name:'Apr'},
    {id:5, name:'May'},
    {id:6, name:'Jun'},
    {id:7, name:'Jul'},
    {id:8, name:'Aug'},
    {id:9, name:'Sep'},
    {id:10, name:'Oct'},
    {id:11, name:'Nov'},
    {id:12, name:'Dec'}
  ];

  years: any = [
    {id:2015, name:2015},
    {id:2016, name:2016},
    {id:2017, name:2017},
    {id:2018, name:2018},
    {id:2019, name:2019},
    {id:2020, name:2020},
    {id:2021, name:2021},
    {id:2022, name:2022},
    {id:2023, name:2023}
  ]

  public error = [];

  constructor(
    private title: Title,
    private formBuilder: FormBuilder,
    private dashboardService: DashboardService,
    // private ymService: YearMonthService,
    // private token : TokenService,
    private clientService: ClientService,
    private teamService: TeamService,
    // private countryService: CountryService,
    private router : Router,
    private dialogRef: MatDialogRef<CreateDashboardComponent>,
    private slugifyPipe : SlugifyPipe,
  ) {
    // if(this.isPageRefresh()){
    //   alert("refresh")
    // }
  }

  ngOnInit() {
    sessionStorage.setItem("win", "A_C");
    this.title.setTitle(this.pageTitle);
    // this.getMonth();
    // this.getYear();
    this.getClients();
    // this.getCountries();
    this.dashboardForm = this.formBuilder.group({
      name: ['', Validators.required],
      slug: '',
      embed_code: ['', Validators.required],
      year: ['', Validators.required],
      month: ['', Validators.required],
      client: '',
      country: '',
      // team: '',
      user_id: this.currentUser(),
      status: ''
    });
  }

  getClients() {
    this.clientService.getClients().subscribe(
      (res: any) => {
        this.clients = res['data'];
      }
    )
  }

  onClientChange(id: number) {
    this.clientId = id;
    this.countries = this.clients.find(client => client.id == id).countries;
  }

  onCountryChange(id: number) {
    this.teamService.getTeams().subscribe(
      (res: any) => {
        // this.teams = res['data'];
        console.log(res['data']);

        const c = res['data'].map(data => data.client)
        .filter((x, i, a) => x && a.indexOf(x) === i);
        // .filter((obj, pos, arr) => {
        //   return arr.map(mapObj => mapObj.id).indexOf(obj.id) === pos;
        // });
        console.log(c);
      }
    )

    // this.countryTeams = this.teams.find(team => team.country.id == id && team.client.id == this.clientId);

    // this.teamService.getTeamsByCountryId(id).subscribe(
    //   (res: any) => {
    //     this.teams = res['data'];
    //     console.log(this.teams);
    //   }
    // )
  }

  // // onSelect(clientID: number) {
  // onSelect() {
  //   // console.log(clientID);
  //   // this.countries$ = this.clients$.find(c => c.name = client).countries;
  //   // this.dashboardForm.get('client').value.pipe(
  //   //   map((val: any[]) => val.reduce((pre, elem) => [...pre, elem.options], [])),
  //   //   map(val => val.flat())
  //   // );
  // }

  // getMonth(): void {
  //   this.ymService.getMonth().subscribe(
  //     // res => this.months = res
  //     res => console.log(res)
  //   )
  // }

  // getYear(): void {
  //   this.ymService.getYear().subscribe(
  //     res => this.years = res
  //   )
  // }

  onSubmit(){
    const formValue = this.dashboardForm.value;
    console.log(formValue);
    
    // console.log(this.form);
    this.dashboardService.createDashboard(formValue).subscribe(
      data => this.handleResponse(data),
      error => this.handleError(error)
    )
  }

  get name() {
    return this.dashboardForm.get('name');
  }

  get embed_code() {
    return this.dashboardForm.get('embed_code');
  }

  handleResponse(data) {
    console.log(data);
    this.dialogRef.close();
    // this.router.navigateByUrl('/admin/dashboards');
  }

  handleError(error) {
    this.error = error.error.errors;
  }

  currentUser(){
    return localStorage.getItem('id');
  }

  slugify(input: string){
    return this.slugifyPipe.transform(input);
  }

  closeBtn() {
    this.dialogRef.close();
  }

}
