import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Team } from '../shared/models/api.model';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TeamService {

  constructor(
    private http: HttpClient
  ) { }

  // Add Team
  createTeam(data){
    return this.http.post(`/team`, data);
  }

  // Get Team
  getTeams(countryId?: number): Observable<Team[]>{
    return this.http.get<Team[]>(`/teams`);
  }

  // Get Team
  getTeam(slug): Observable<Team> {
    return this.http.get<Team>(`/team/`+slug);
    // .pipe(
    //   tap(_ => console.log(`fetched post id=${id}`))
    //   // catchError(this.handleError<Post>(`getPost id=${id}`))
    // );
  }
  getTeamsByCountryId(id): Observable<Team> {
    return this.http.get<Team>(`/team/country/`+id);
    // .pipe(
    //   tap(_ => console.log(`fetched post id=${id}`))
    //   // catchError(this.handleError<Post>(`getPost id=${id}`))
    // );
  }
}
