import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class YearMonthService {

  public months: any = [
    {id:1, name:'Jan'},
    {id:2, name:'Feb'},
    {id:3, name:'Mar'},
    {id:4, name:'Apr'},
    {id:5, name:'May'},
    {id:6, name:'Jun'},
    {id:7, name:'Jul'},
    {id:8, name:'Aug'},
    {id:9, name:'Sep'},
    {id:10, name:'Oct'},
    {id:11, name:'Nov'},
    {id:12, name:'Dec'}
  ];

  public years: any = [
    {id:2015, name:2015},
    {id:2016, name:2016},
    {id:2017, name:2017},
    {id:2018, name:2018},
    {id:2019, name:2019}
  ]

  constructor(
    private http: HttpClient
  ) { }

  // Get Month
  getMonths() {
    return this.http.get(this.months);
  }

  // Get Year
  getYears() {
    return this.http.get(this.years);
  }
}
