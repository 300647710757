import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchFilter'
})
export class SearchFilterPipe implements PipeTransform {

  transform(list: any[], filterText: string): any {
    if(list){
      console.log("list",list);
      return list ? list.filter(item => item.key.search(new RegExp(filterText, 'i')) > -1) : [];
    }
  }

}