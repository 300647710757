import { Injectable } from '@angular/core';
import { KeyValue } from '@angular/common';
import { User } from '../../shared/models/api.model';

@Injectable({
    providedIn: 'root'
})
export class KVComparator {
    orderByRowNum = (aKv: KeyValue<string, any>, bKv: KeyValue<string, any>) => {

        const rx: RegExp = /(\d+)|(\D+)/g;
        const rd: RegExp = /\d+/;
    
        const as: string = aKv.key;
        const bs: string = bKv.key;
    
        const a: RegExpMatchArray = String(as).match(rx);
        const b: RegExpMatchArray = String(bs).match(rx);
    
        while (a.length && b.length) {
            const a1: string = a.shift();
            const b1: string = b.shift();
            if (rd.test(a1) || rd.test(b1)){
                if (!rd.test(a1)) {
                    return 1;
                }
                if (!rd.test(b1)) {
                    return -1;
                }
                if (a1 !== b1) {
                    return Number(a1) - Number(b1);
                }
            } else {
                if (a1 !== b1) {
                    return a1.localeCompare(b1);
                }
            }
        }
        return a.length - b.length;
    }

    orderByRowVal(a:KeyValue<User, User>, b:KeyValue<User, User>) {
        if (a.key.name===b.key.name) {
        return 0;
        }
        return a.key.name < b.key.name ? -1 : 1;
        
    }

    returnZero() {
        return 0
    }

    compareFn(as,bs) {
        const rx: RegExp = /(\d+)|(\D+)/g;
        const rd: RegExp = /\d+/;

        const a: RegExpMatchArray = String(as).match(rx);
        const b: RegExpMatchArray = String(bs).match(rx);
    
        while (a.length && b.length) {
            const a1: string = a.shift();
            const b1: string = b.shift();
            if (rd.test(a1) || rd.test(b1)){
                if (!rd.test(a1)) {
                    return 1;
                }
                if (!rd.test(b1)) {
                    return -1;
                }
                if (a1 !== b1) {
                    return Number(a1) - Number(b1);
                }
            } else {
                if (a1 !== b1) {
                    return a1.localeCompare(b1);
                }
            }
        }
        
        return a.length - b.length;
    }

    JsonTryParseReturnNumber(value: string) {
        try {
          return JSON.parse(value);
        } catch (e) {
          if (value === 'undefined') {
            return void 0;
          }
          return Number(value);
        }
      }
}