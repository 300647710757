import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { TokenService } from '../../services/token.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public form = {
    email: null,
    password: null
  }

  // userID: number;

  public error = null;

  constructor(
    private userService: UserService,
    private token: TokenService,
    private router: Router,
    private auth: AuthService
  ) { }

  ngOnInit() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('login');
  }

  onSubmit(){
    // console.log(this.form);
    this.userService.login(this.form).subscribe(
      data => this.handleResponse(data),
      error => this.handleError(error)
    )
  }

  handleResponse(data) {
    // console.log(data);
    this.token.handle(data.access_token, data.user, data.role);
    this.auth.changeAuthStatus(true);
    this.auth.setUser(data.user['name']);
    // localStorage.setItem('c_user', data.user_id)
    if(this.auth.roleMatch(['Admin', 'Super Admin'])) {
      // this.router.navigateByUrl('/admin');
      this.router.navigate(['admin']);
    } else {
      this.router.navigate(['dashboards']);
    }
  }

  handleError(error) {
    this.error = error.error.error;
  }

  ngOnDestroy(): void {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('login');
  }

}
