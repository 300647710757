import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Client } from '../shared/models/api.model';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  constructor(
    private http: HttpClient
  ) { }

  // Add Client
  createClient(data){
    return this.http.post(`/client`, data);
  }

  // Get Clients
  getClients(): Observable<Client[]>{
    return this.http.get<Client[]>(`/clients`);
  }

  // Get Client
  getClient(slug): Observable<Client> {
    return this.http.get<Client>(`/client/`+slug);
    // .pipe(
    //   tap(_ => console.log(`fetched post id=${id}`))
    //   // catchError(this.handleError<Post>(`getPost id=${id}`))
    // );
  }

  // Edit Client
  editClient(client: Client) {
    return this.http.put(`/client`, client);
  }

  // Add Client Country(s)
  addClientCountry(data){
    return this.http.post(`/client/add-countries`, data);
  }

  // Remove Client Country
  removeClientCountry(data){
    return this.http.post(`/client/remove-countries`, data);
  }
}
