import { BrowserModule } from '@angular/platform-browser'; //Title
import { NgModule } from '@angular/core';
// import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faBars, faPlus } from '@fortawesome/free-solid-svg-icons';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './/app-routing.module';
import { NavbarComponent } from './components/navbar/navbar.component';
import { LoginComponent } from './components/login/login.component';
// import { RegisterComponent } from './components/register/register.component';
import { ProfileComponent } from './components/profile/profile.component';
import { RequestResetComponent } from './components/password/request-reset/request-reset.component';
import { ResponseResetComponent } from './components/password/response-reset/response-reset.component';

import { SnotifyModule, SnotifyService, ToastDefaults } from 'ng-snotify';
// import { SlugifyPipe } from './shared/pipes/slugify.pipe';
// import { FileDropModule } from 'ngx-file-drop';
import { FlexLayoutModule } from '@angular/flex-layout';
// import { SharedPipesModule } from './shared-pipes.module';
// import { SafeUrlPipe } from './shared/pipes/safeurl.pipe';
import 'hammerjs';

import { MaterialModule } from './material.module';
import { AuthModule } from './auth/auth.module';
import { CoreModule } from './core/core.module';
import { PublicModule } from './components/public/public.module';
import { AdminModule } from './components/admin/admin.module';
// import { AuthInterceptor } from './interceptors/auth-interceptor';

// import { CreatePostComponent } from './components/admin/create-post/create-post.component';
// import { PostsComponent } from './components/posts/posts.component';
// import { PostDetailComponent } from './components/post-detail/post-detail.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { HomeComponent } from './components/home/home.component';
// import { CategoryComponent } from './components/admin/category/category.component';
// import { MediaComponent } from './components/admin/media/media.component';
// import { CreateDashboardComponent } from './components/admin/dashboards/create-dashboard/create-dashboard.component';
// import { UsersListComponent } from './components/admin/users/users-list/users-list.component';
// import { DashboardsListComponent } from './components/admin/dashboards/dashboards-list/dashboards-list.component';
// import { CreateClientComponent } from './components/admin/clients/create-client/create-client.component';
// import { ClientsListComponent } from './components/admin/clients/clients-list/clients-list.component';
// import { AddTeamComponent } from './components/admin/teams/add-team/add-team.component';
// import { TeamsListComponent } from './components/admin/teams/teams-list/teams-list.component';
// import { AddPermissionComponent } from './components/admin/permissions/add-permission/add-permission.component';
// import { PermissionsListComponent } from './components/admin/permissions/permissions-list/permissions-list.component';
// import { AddRoleComponent } from './components/admin/roles/add-role/add-role.component';
// import { RolesListComponent } from './components/admin/roles/roles-list/roles-list.component';
// import { AddUserComponent } from './components/admin/users/add-user/add-user.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
// import { AdminComponent } from './components/admin/admin.component';
import { ForbiddenComponent } from './components/forbidden/forbidden.component';
// import { EditClientComponent } from './components/admin/clients/edit-client/edit-client.component';
// import { EditDashboardComponent } from './components/admin/dashboards/edit-dashboard/edit-dashboard.component';
// import { AssignUserComponent } from './components/admin/dashboards/assign-user/assign-user.component';
// import { DashboardUsersListComponent } from './components/admin/dashboards/dashboard-users-list/dashboard-users-list.component';
// import { AddDashboardUserComponent } from './components/admin/dashboards/add-dashboard-user/add-dashboard-user.component';
// import { DashboardsComponent } from './components/public/dashboards/dashboards.component';
// import { DashboardDetailComponent } from './components/public/dashboards/dashboard-detail/dashboard-detail.component';
// import { EditUserComponent } from './components/admin/users/edit-user/edit-user.component';
// import { AdminDashboardDetailComponent } from './components/admin/dashboards/admin-dashboard-detail/admin-dashboard-detail.component';
// import { UserDetailComponent } from './components/admin/users/user-detail/user-detail.component';
// import { UserDashboardListComponent } from './components/admin/users/user-dashboard-list/user-dashboard-list.component';
// import { AddUserDashboardComponent } from './components/admin/users/add-user-dashboard/add-user-dashboard.component';
import { SearchBarComponent } from './shared/search-bar/search-bar.component';
// import { TeamDetailComponent } from './components/admin/teams/team-detail/team-detail.component';
// import { EditTeamComponent } from './components/admin/teams/edit-team/edit-team.component';
// import { TeamClientListComponent } from './components/admin/teams/team-client-list/team-client-list.component';
// import { TeamClientCountryListComponent } from './components/admin/teams/team-client-country-list/team-client-country-list.component';
import { InitialsPipe } from './shared/pipes/initials.pipe';
// import { ClientDetailComponent } from './components/admin/clients/client-detail/client-detail.component';
// import { ClientCountriesListComponent } from './components/admin/clients/client-countries-list/client-countries-list.component';
// import { AddClientCountryComponent } from './components/admin/clients/add-client-country/add-client-country.component';
import { SearchFilterPipe } from './shared/pipes/search-filter.pipe';
// import { LoaderComponent } from './components/loader/loader.component';

import { NgIdleModule } from '@ng-idle/core';
import { LayoutModule } from '@angular/cdk/layout';
// import { MatToolbarModule, MatButtonModule, MatSidenavModule, MatIconModule, MatListModule } from '@angular/material';
// import { AdminNavComponent } from './components/admin/admin-nav/admin-nav.component';
// import { SessionTimeoutComponent } from './components/session-timeout/session-timeout.component';
import { AdminSideNavTogglerComponent } from './components/admin/admin-side-nav-toggler/admin-side-nav-toggler.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    LoginComponent,
    // RegisterComponent,
    ProfileComponent,
    RequestResetComponent,
    ResponseResetComponent,
    // CreatePostComponent,
    // PostsComponent,
    // PostDetailComponent,
    PageNotFoundComponent,
    HomeComponent,
    // CategoryComponent,
    // SlugifyPipe,
    // MediaComponent,
    // CreateDashboardComponent,
    // UsersListComponent,
    // DashboardsListComponent,
    // CreateClientComponent,
    // ClientsListComponent,
    // AddTeamComponent,
    // TeamsListComponent,
    // AddPermissionComponent,
    // PermissionsListComponent,
    // AddRoleComponent,
    // RolesListComponent,
    // AddUserComponent,
    HeaderComponent,
    FooterComponent,
    // AdminComponent,
    ForbiddenComponent,
    // EditClientComponent,
    // EditDashboardComponent,
    // AssignUserComponent,
    // DashboardUsersListComponent,
    // AddDashboardUserComponent,
    // DashboardsComponent,
    // DashboardDetailComponent,
    // SafeUrlPipe,
    // EditUserComponent,
    // AdminDashboardDetailComponent,
    // UserDetailComponent,
    // UserDashboardListComponent,
    // AddUserDashboardComponent,
    SearchBarComponent,
    // TeamDetailComponent,
    // EditTeamComponent,
    // TeamClientListComponent,
    // TeamClientCountryListComponent,
    InitialsPipe,
    // ClientDetailComponent,
    // ClientCountriesListComponent,
    // AddClientCountryComponent,
    SearchFilterPipe,
    // AdminNavComponent,
    // LoaderComponent
    // SessionTimeoutComponent
    AdminSideNavTogglerComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    // FormsModule,
    // ReactiveFormsModule,
    BrowserAnimationsModule,
    // HttpClientModule,
    SnotifyModule,
    // FileDropModule,
    FlexLayoutModule,
    MaterialModule,
    // SharedPipesModule
    FontAwesomeModule,

    AuthModule.forRoot(),
    CoreModule.forRoot(),
    PublicModule,
    AdminModule,

    NgIdleModule.forRoot(),

    LayoutModule,

    // MatToolbarModule,

    // MatButtonModule,

    // MatSidenavModule,

    // MatIconModule,

    // MatListModule,

    // SessionTimeoutComponent
  ],
  providers: [
    { provide: 'SnotifyToastConfig', useValue: ToastDefaults},
    // Title,
    SnotifyService,
    // SlugifyPipe,
    // SafeUrlPipe,
    InitialsPipe,
    // { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    // MediaComponent,
    // CreateClientComponent,
    // EditClientComponent,
    // CreateDashboardComponent,
    // EditDashboardComponent,
    // AssignUserComponent,
    // DashboardUsersListComponent,
    // AddDashboardUserComponent,
    // AddUserComponent,
    // EditUserComponent,
    // AdminDashboardDetailComponent,
    // UserDetailComponent,
    // UserDashboardListComponent,
    // AddUserDashboardComponent,
    // AddTeamComponent,
    // TeamDetailComponent,
    // ClientDetailComponent,
    // ClientCountriesListComponent,
    // AddClientCountryComponent,
    // EditClientComponent,
    // TeamClientCountryListComponent,
    // TeamsListComponent
    // SessionTimeoutComponent
  ]
})
export class AppModule {
  constructor() {
    library.add(faBars, faPlus);
  }
}
