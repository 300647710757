import { ApplicationRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material';

// import { Keepalive } from '@ng-idle/keepalive';
import { DEFAULT_INTERRUPTSOURCES, Idle, AutoResume } from '@ng-idle/core';

import { SessionTimeoutComponent } from './components/session-timeout/session-timeout.component';
import { ActivityMonitorService } from './services/activity-monitor.service';
import { AuthService } from './services/auth.service';
// import { TokenService } from './services/token.service';
import { LocalStoreManager } from './services/local-store-manager.service';
import { Observable, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  isAppLoaded: boolean;
  loggedIn: boolean;
  isAdmin: boolean = false;
  title = 'Tomorrow TTH | Portal';
  popRef: MatDialogRef<SessionTimeoutComponent>;

  status;
  idleState = 'Not started.';
  timedOut = false;
  hasIdled = false;
  lastPing?: Date = null;
  tokenExpires: any;

  notIdle: any;

  private idleEndSubscription: Subscription;

  currentPath: string;

  constructor(
    public storageManager: LocalStoreManager,
    public dialog: MatDialog,
    private idle: Idle,
    // private keepalive: Keepalive,
    private amService: ActivityMonitorService,
    private auth: AuthService,
    private appRef: ApplicationRef,
    private router: Router,
    location: Location
  ) {
    this.storageManager.initialiseStorageSyncListener();

    this.auth.authStatus.subscribe(
      (value) => {
        // console.log("NAV LoggedIN: ",value);
        this.loggedIn = value;
      }
    );

    this.userRole();

    // this.isPageRefresh();
    
    // Activity Monitor settings
    idle.setIdle(1800); // Executes when inactive for 1 hour
    idle.setTimeout(10); // Executes a 15 minute countdown prior to Timeout
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    idle.setAutoResume(AutoResume.notIdle);

    this.idleEndSubscription = idle.onIdleEnd.subscribe(() => {
      this.isLoggedIn();
      // console.log(this.loggedIn);
      // console.log(this.auth.authStatus);
      // const timerStatus = this.storageManager.getData('accessToken');
      // console.log(timerStatus);
      // console.log('idle end reached');
      this.popRef.close();
      if(!this.loggedIn) this.router.navigate(['login']);
      // if(timerStatus === "End") this.stop();
      // idle.watch();
      // this.appRef.tick(); //close dialog on activity.
      // this.auth.authStatus.subscribe(
      //   (value) => {
      //     console.log(value);
      //     if(!value){
      //       if(dialog.openDialogs.length > 0){
      //         this.popRef.close();
      //         // this.appRef.tick(); //close dialog on activity.
      //       }
      //       this.subscription.unsubscribe();
      //       this.router.navigate(['login']);
      //     }
      //   }
      // )
      // if (this.auth.isAuthenticated()) {
      //     this.authService.refreshToken().subscribe();
      // }
  
      // if(dialog.openDialogs.length > 0){
      //   this.popRef.close();
      //   // this.appRef.tick(); //close dialog on activity.
      // }
      // this.idleEndSubscription.unsubscribe();
  });

    // idle.onIdleEnd.subscribe(() => {
    //   this.hasIdled = false;
    //   this.idleState = `No longer idle.`;
    //   this.status = { state: this.idleState, active: this.hasIdled };
    //   console.log('Act-Mon', this.status.state);
    //   if(dialog.openDialogs.length > 0){
    //     this.popRef.close();
    //     // this.appRef.tick(); //close dialog on activity.
    //   }
    //   finalize(() => {
    //     this.auth.authStatus.subscribe(
    //       (value) => {
    //         console.log(value);
    //         if(!value){
    //           this.router.navigate(['login']);
    //         }
    //       }
    //     )
    //   })
    // });

    idle.onTimeout.subscribe(() => {
      this.idleState = `Timed out!`;
      this.timedOut = true;
      this.status = { state: this.idleState, active: false };
      this.amService.setStatus(this.status);
      this.logout();
    });

    idle.onIdleStart.subscribe(() =>  {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.panelClass = 'session-message';
      // dialogConfig.hasBackdrop = true;
      dialogConfig.backdropClass = 'no-click-backdrop';
      // dialogConfig.disableClose = false;
      this.popRef = this.dialog.open(SessionTimeoutComponent, dialogConfig);
      this.popRef.afterClosed().subscribe(
        (res) => {
          // console.log("RESULT", res);
          if(res === "extend session") {
            // console.log(res);
            idle.watch();
          }else{
            if(res === "end session"){
              // console.log("LOGOUT");
              // this.stop();
              this.logout();
            }
          }
          // if(res === undefined) {
          //   console.log("IDLE STATE",this.idleState);
          //   if(Number(this.idleState) < 0 || this.idleState == "Time out!" || this.idleState == "Started") {
          //     this.timedOut = true;
          //     this.status = { state: this.idleState, active: false };
          //     this.amService.setStatus(this.status);
          //     this.stop();
          //   }
          //   // this.notIdle = "not idle";
          //   // console.log("YAY!!!!!!!!!!!!!!!!!!!!!!!!!",this.idleState);
          //   // idle.watch();
          // }
          // // else {
          // //   console.log("NAY!!!!!!!!!!!!!!!!!!!!!!!");
          // // }
        }
      )
      // console.log("TIMED OUT",this.timedOut);
      this.hasIdled = true;
      this.idleState = `You've gone idle!`;
      this.tokenExpires = localStorage.getItem('expires_at');
      this.status = { state: this.idleState, active: this.hasIdled, expires: this.tokenExpires };
      // console.log(this.idleState);
      this.amService.setStatus(this.status);
    });
    
    idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = countdown;
      this.status = { state: this.idleState, active: this.hasIdled, expires: this.tokenExpires, countdown: countdown };
      // console.log(this.idleState);
      this.amService.setStatus(this.status);
    });

    // keepalive.interval(30); // Executes every 30 minutes
    // keepalive.onPing.subscribe(() => {
    //   this.lastPing = new Date();
    // });

    // Lets check the path everytime the route changes, stop or start the idle check as appropriate.
    this.router.events.subscribe((val) => {
      this.currentPath = location.path();
      // console.log(this.currentPath);
      if(this.currentPath.search(/login/gi) == -1)
        this.start();
      else
        this.stop();
    });
    
  }

  ngOnInit() {
    setTimeout(() => {
      // console.log("UID",sessionStorage.getItem('id'));
      this.isAppLoaded = true;
    }, 500);
    
  }

  // loading = true;
  // @HostListener('window:beforeunload', ['$event'])
  // canLeavePage($event: any): Observable<void> {
  //   if(this.loading && prompt('You data is loading. Are you sure you want to leave?')) {
  //     $event.preventDefault();
  //   }
  // }

  // isPageRefresh(){
  //   return !this.router.navigated;
  //   // console.log("ALPHA")
  // }
  isLoggedIn(){
    this.auth.checkAuth().subscribe(
      (value) => {
        // console.log("AUTH STATUS: ",value);
        this.loggedIn = value
      }
    );
  }

  userRole() {
    const userRole = this.storageManager.getData('ur');
    // console.log("A UserRole: ",userRole)
    if(userRole === "Admin" || userRole === "Super Admin"){
      this.isAdmin = true;
    } else {
      this.isAdmin = false;
    }
  }

  public start(): void {
    // console.log('started AM feature');
    this.idle.watch();
    this.idleState = 'Started';
    this.timedOut = false;
    this.status = { state: this.idleState };
    this.amService.setStatus(this.status);
  }

  public stop(): void {
    this.idle.stop();
    // console.log('AM feature stopped');
  }

  public logout() {
    this.popRef.close();
    // this.stop();
    // this.appRef.tick(); //close dialog on activity.
    this.auth.logout().subscribe(
      (data) => {
        // console.log(data);
        this.stop();
      },
      // error => console.log(error)
    );
  }
}
