import { Component, OnInit, Inject, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material';
import { DashboardService } from '../../../../services/dashboard.service';
// import { TokenService } from '../../../../services/token.service';
import { UserService } from '../../../../services/user.service';
// import { Router } from '@angular/router';
import { Observable, fromEvent } from 'rxjs';
import { User, Dashboard} from '../../../../shared/models/api.model';
import { KVComparator } from '../../../../shared/utils/utils';
import { map, debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';

@Component({
  selector: 'app-add-dashboard-user',
  templateUrl: './add-dashboard-user.component.html',
  styleUrls: ['./add-dashboard-user.component.css']
})
export class AddDashboardUserComponent implements OnInit {

  pageTitle = "Add Dashboard Users";
  users: any = [];
  filteredUsers: any = [];
  curUsers: any = [];
  dashboard: any = [];
  assignUserForm: FormGroup;

  hide: any = {};

  public error = [];

  constructor(
    private title: Title,
    private formBuilder: FormBuilder,
    private dashboardService: DashboardService,
    private userService: UserService,
    public kvOrder: KVComparator,
    private dialogRef: MatDialogRef<AddDashboardUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.log(data);
    this.dashboard = data.dashboard;
    this.data.dashboard.users.forEach((user: any) => {
      this.curUsers.push(user.id);
    });
    console.log(this.curUsers);
  }

  @ViewChild('userSearch') input: ElementRef;

  ngOnInit() {
    this.title.setTitle(this.pageTitle);

    this.getUsers();
    this.filteredUsers = this.users;
    this.assignUserForm = this.formBuilder.group({
      id: this.dashboard.id,
      users: this.formBuilder.group([])
    });
    this.usersFormControls();
  }

  ngAfterViewInit() {
    fromEvent(this.input.nativeElement, 'keyup')
    .pipe(
      debounceTime(150),
      distinctUntilChanged(),
      tap(() => {
        this.filterUsers();
        // console.log("Filtered: ", this.fDashboards);
      })
    )
    .subscribe();

  }

  dashboardData(dashboard:any){
    let dashboardUsers = dashboard.users;
    for(let i = 0; i < dashboardUsers.length; i++){
      dashboardUsers[i].shown = true;
    }
    this.dashboard = dashboard;
    // console.log("TEST! ",user);
  }

  filterUsers() {
    var searchTerm = this.input.nativeElement.value;
    
    this.users.filter(
      (item) => {
        let m: boolean = item.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
        // console.log("BOOL: ", m);
        (m) ? item.shown = true : item.shown = false;
      }
    );

  }

  getUsers() {
    this.userService.allUserFullNames().subscribe(
      (res: any) => {
        res['data'].forEach((item) => {
          item.shown = true;
          this.users.push(item);
        })
      // (res: any) => {
      //   this.users = res['data'];
        this.filteredUsers = this.users;
        console.log(res['data']);
        // this.filteredUsers.sort((a,b) => a.name.localeCompare(b.name));
        // console.log(this.users);
        // this.filteredUsers.forEach((user: any) => {
        //   const users = <FormGroup>this.assignUserForm.get('users');
        //   let uDisabled: boolean;
        //   (this.curUsers.includes(user.id)) ? uDisabled = true : uDisabled = false;
        //   users.addControl(user.id, new FormControl({value: false, disabled: uDisabled}));
        // });
        // console.log(this.assignUserForm.getRawValue().users);
      }
    )
  }

  usersFormControls() {
    // (<FormGroup>this.assignUserForm.get('users')).reset();
    setTimeout(()=>{this.filteredUsers.sort((a,b) => a.name.localeCompare(b.name));
    console.log(this.filteredUsers);
    this.filteredUsers.forEach((user: any) => {
      const users = <FormGroup>this.assignUserForm.get('users');
      let uDisabled: boolean;
      (this.curUsers.includes(user.id)) ? uDisabled = true : uDisabled = false;
      users.addControl(user.id, new FormControl({value: false, disabled: uDisabled}));
    });
    console.log(this.assignUserForm.getRawValue().users);
  }, 1000);
  }

  applyFilter(e: string) {
    // const query = e;

    // convert to lowercase to do case insensitive search
    const query = e.toLowerCase();

    if(query === ""){
      this.filteredUsers = this.users;
      this.usersFormControls();
    } else {
      this.filteredUsers = this.users.filter(item => item.name.toLowerCase().indexOf(query) !== -1);
      this.usersFormControls();
    }
    // this.users.filter = query.trim();
    console.log("Filtered Users: ", this.filteredUsers);
}



  onSubmit(){
    // console.log(this.assignUserForm.value);
    var usersObj = this.assignUserForm.value.users;
    var selectedUserIds = [];
    for (var userId in usersObj) {
      if (usersObj.hasOwnProperty(userId)) {
        if(usersObj[userId])//If selected
        {
          selectedUserIds.push(userId);
        }
      }
    }

    let data = {
      id: this.assignUserForm.value.id,
      users: selectedUserIds
    }

    console.log(data);

    this.dashboardService.addDashboardUser(data).subscribe(
      data => this.handleResponse(data),
      error => this.handleError(error)
    )
  }

  handleResponse(data) {
    console.log(data);
    this.dialogRef.close();
    // this.router.navigateByUrl('/admin/dashboards');
  }

  handleError(error) {
    this.error = error.error.errors;
  }

  closeBtn(): void{
    this.dialogRef.close();
  }


}
