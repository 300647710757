import { Component, OnInit, AfterViewInit, ViewChild, OnDestroy } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Client } from '../../../../shared/models/api.model';
import { ClientService } from '../../../../services/client.service';
// import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { CreateClientComponent } from '../create-client/create-client.component';
import { EditClientComponent } from '../edit-client/edit-client.component';
import { ClientDetailComponent } from '../client-detail/client-detail.component';
import { Observable } from 'rxjs';
import { library } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'app-clients-list',
  templateUrl: './clients-list.component.html',
  styleUrls: ['./clients-list.component.css']
})
export class ClientsListComponent implements OnInit, OnDestroy {
  // faPlus = faPlus;

  clients: Observable<Client[]>;  

  displayedColumns: string[] = ['id', 'name', 'teams', 'users'];
  dataSource = new MatTableDataSource<any>();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private clientService: ClientService,
    // private router: Router,
    private dialog: MatDialog
  ) {
    library.add(faPlus);
  }

  ngOnInit() {
    this.getClients();

    const body = document.getElementsByTagName('body')[0];
    body.classList.add('clients');
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  getClients(): void {
    this.clientService.getClients().subscribe(
      (res: Client[]) => {
        // console.log(res);        
        this.clients = res['data'];
        this.dataSource.data = res['data'];
      }
    )
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  addClient() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('no-scroll');
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'menu-reveal';
    const addClient = this.dialog.open(CreateClientComponent, dialogConfig);
    addClient.afterClosed().subscribe(
      () => {
        const body = document.getElementsByTagName('body')[0];
        body.classList.remove('no-scroll');
        this.clientService.getClients().subscribe(
          (res) => {
            this.dataSource.data = res['data'];
            console.log("updated clients",this.dataSource);
          }
        )
      }
    )
  }

  clientDetail(slug: string) {
    // console.log(client);
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('no-scroll');
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'menu-reveal';
    dialogConfig.data = {
      slug: slug
    };
    const clientDetail = this.dialog.open(ClientDetailComponent, dialogConfig);
    clientDetail.afterClosed().subscribe(
      () => {
        const body = document.getElementsByTagName('body')[0];
        body.classList.remove('no-scroll');
        this.clientService.getClients().subscribe(
          (res) => {
            this.dataSource.data = res['data'];
            console.log("updated clients",this.dataSource);
          }
        )
      }
    )
  }

  editClient(client) {
    // console.log(client);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { client: client };
    this.dialog.open(EditClientComponent, dialogConfig);
  }

  ngOnDestroy(): void {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('clients');
  }

}
