import { Component, OnInit, Inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { UserService } from '../../../../services/user.service';
import { Observable } from 'rxjs';
import { Client, Country, Role } from '../../../../shared/models/api.model';
import { map, tap } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material';
import { User } from '../../../../shared/models/user.model';
import { EditUserComponent } from '../edit-user/edit-user.component';
import { UserDashboardListComponent } from '../user-dashboard-list/user-dashboard-list.component';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.css']
})
export class UserDetailComponent implements OnInit {

  pageTitle = "User Detail";
  hide = true;

  userId: number;
  user$: Observable<User>;
  // clients$: Observable<Client[]>;
  // countries$: Observable<Country[]>;
  // roles$: Observable<Role[]>;

  // status: boolean;
  // client;
  // country;
  // role;

  public error = [];

  constructor(
    private userService: UserService,
    private title: Title,
    private dialog : MatDialog,
    private dialogRef: MatDialogRef<UserDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.userId = data.userId;
    // this.user$ = data.user;
    // this.client = data.user.client[0]['name'];
    // this.country = data.user.country[0]['name'];
    // this.role = data.user.roles[0]['name'];
    // console.log(data.user.client[0]['id']);
  }

  ngOnInit() {
    this.title.setTitle(this.pageTitle);
    this.getUser();
  }

  getUser() {
    this.userService.getUser(this.userId).subscribe(
      (res: User) => {
        this.user$ = res['data'];
        console.log("curUser", this.user$);
        console.log("user dashboards", this.user$['dashboards']);
        // (this.user$['status'] === "publish") ? this.status = true : this.status = false;
      }
    )
  }

  editUser(user) {
    // console.log(client);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'menu-reveal';
    dialogConfig.data = { user: user };
    const editUser = this.dialog.open(EditUserComponent, dialogConfig);
    editUser.afterClosed().subscribe(
      () => {
        // let userId = localStorage.getItem("curUser");
        this.userService.getUser(this.user$['id']).subscribe(
          (res) => {
            this.user$ = res['data'];
            // this.client = this.user$['client'][0]['name']
            // this.country = this.user$['country'][0]['name']
            // this.role = this.user$['roles'][0]['name']
            console.log("updated user",this.user$);
          }
        )
      }
    )
  }

  editDashboardList() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'menu-reveal';
    dialogConfig.data = { user: this.user$ };
    const userDashboards = this.dialog.open(UserDashboardListComponent, dialogConfig);
    userDashboards.afterClosed().subscribe(
      (data) => {
        this.userService.getUser(this.user$['id']).subscribe(
          (res) => {
            this.user$ = res['data'];
            console.log("new user",this.user$);
          }
        )
      }
    )
  }

  handleResponse(data) {
    console.log(data);
    // this.router.navigateByUrl('/admin/users');
  }

  handleError(error) {
    this.error = error.error.errors;
  }

  closeBtn(): void{
    this.dialogRef.close();
  }

}
