import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { MatDialog, MatDialogConfig, PageEvent } from '@angular/material';
import { Dashboard, Client, Country } from '../../../../shared/models/api.model';
import { DashboardService } from '../../../../services/dashboard.service';
import { ClientService } from '../../../../services/client.service';
import { CountryService } from '../../../../services/country.service';
import { CreateDashboardComponent } from '../create-dashboard/create-dashboard.component';
import { AdminDashboardDetailComponent } from '../admin-dashboard-detail/admin-dashboard-detail.component';
import { DashboardFilterComponent } from '../../../../components/dashboard-filter/dashboard-filter.component';
// import { DateFormatPipe } from '../../../../shared/pipes/date-format.pipe';
import { LocalStoreManager } from '../../../../services/local-store-manager.service';
import { KVComparator } from '../../../../shared/utils/utils';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import * as _ from 'lodash';
// import { DashboardFilterService } from '../../../../services/dashboard-filter.service';

// import { DashboardsDataSource } from '../../../../services/dashboards.datasource';

@Component({
  selector: 'app-dashboards-list',
  templateUrl: './dashboards-list.component.html',
  styleUrls: ['./dashboards-list.component.css']
})
export class DashboardsListComponent implements AfterViewInit, OnInit, OnDestroy {

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );

  pageTitle = 'Admin - Dashboards';

  // dataSource: DashboardsDataSource;

  dashboards$: Observable<Dashboard[]>;
  dashboards: any = [];
  clients: any = [];
  countries: any = [];
  filteredDashboards: any = [];

  pagedList: Dashboard[]= [];
  breakpoint: number = 3;  //to adjust to screen
  // MatPaginator Inputs
  length: number = 0;
  pageIndex: number = 0;
  pageSize: number = 20;  //displaying three cards each row
  pageSizeOptions: number[] = [20, 40, 60, 100];


  /// Active filter rules
  // f_array = [];
  // filters = {};

  // changeArr = [];
  changeObj = {};
  
  /// Active filter rules
  client = this._utils.JsonTryParseReturnNumber(sessionStorage.getItem('client'));
  country = this._utils.JsonTryParseReturnNumber(sessionStorage.getItem('country'));
  month = this._utils.JsonTryParseReturnNumber(sessionStorage.getItem('month'));
  year = this._utils.JsonTryParseReturnNumber(sessionStorage.getItem('year'));

  months: any = [
    {id:1, name:'Jan'},
    {id:2, name:'Feb'},
    {id:3, name:'Mar'},
    {id:4, name:'Apr'},
    {id:5, name:'May'},
    {id:6, name:'Jun'},
    {id:7, name:'Jul'},
    {id:8, name:'Aug'},
    {id:9, name:'Sep'},
    {id:10, name:'Oct'},
    {id:11, name:'Nov'},
    {id:12, name:'Dec'}
  ];

  years: any = [
    {id:2015, name:2015},
    {id:2016, name:2016},
    {id:2017, name:2017},
    {id:2018, name:2018},
    {id:2019, name:2019},
    {id:2020, name:2020},
    {id:2021, name:2021},
    {id:2022, name:2022},
    {id:2023, name:2023}
  ];

  constructor(
    private localStorage: LocalStoreManager,
    private _utils: KVComparator,
    private title: Title,
    private dashboardService: DashboardService,
    // public dashboardFilterService: DashboardFilterService,
    private breakpointObserver: BreakpointObserver,
    private clientServics: ClientService,
    private countryService: CountryService,
    private router: Router,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.title.setTitle(this.pageTitle);
    // console.log(this.client);
    // console.log("iFilters: ",this.filters);
    this.setFilters();
    this.applyFilters();

    // this.dataSource = new DashboardsDataSource(this.dashboardService);
    // this.dataSource.loadDashboards('', 'id', 'desc', 0, 2);

    // this.getDashboards();
    this.getClients();
    this.getCountries();

    this.breakpoint = (window.innerWidth <= 800) ? 1 : 3;
    // this.pagedList = this.filteredDashboards.slice(0, 3);
    // this.length = this.filteredDashboards.length;

    const body = document.getElementsByTagName('body')[0];
    body.classList.add('dashboard');
    // console.log("PagedList", this.pagedList);
  }

  ngAfterViewInit() {
    // this.pageIndex = 0;
    
    // this.dataSource.dashboardsCount$
    //   .pipe(
    //     tap((count) => {
    //       this.length = count;
    //     })
    //   ).subscribe();
  }

  setFilters() {
    // let curClient = this._utils.JsonTryParseReturnNumber(sessionStorage.getItem('client'));
    // let curCountry = this._utils.JsonTryParseReturnNumber(sessionStorage.getItem('country'));
    // let curMonth = this._utils.JsonTryParseReturnNumber(sessionStorage.getItem('month'));
    // let curYear = this._utils.JsonTryParseReturnNumber(sessionStorage.getItem('year'));
    

    // console.log("Client --- ",this.client);
    // console.log("Filter --- ",this.filters);

    // if(this.client) this.f_array.push({"client":this.client});
    // if(this.country) this.f_array.push({"country":this.country});
    // if(this.month) this.f_array.push({"month":this.month});
    // if(this.year) this.f_array.push({"year":this.year});
    
    if(this.client) this.changeObj['client'] = this.client;
    if(this.country) this.changeObj['country'] = this.country;
    if(this.month) this.changeObj['month'] = this.month;
    if(this.year) this.changeObj['year'] = this.year;

    // this.f_array.find(ob => ob['client'] = this.client);
    // console.log("F_ARRAY: ",this.f_array);

    // let ec = this.f_array.find(ob => ob['client']);
    // if (ec) {

    // }


    // console.log("Existing Client: ", ec);

    // for(let key in this.f_array){
    //   console.log(this.f_array[key], this.f_array[key].val);
    // }

    // this.f_array.forEach(item => {
    //   // console.log("LS Filters - ", this.filters);
    //   for(let key in item){
    //     if(item.hasOwnProperty(key)){
    //       // console.log(key, item[key])
    //       let property: string = key;
    //       let rule: number = item[key];
    //       if(!rule) {
    //         delete this.filters[property];
    //         this[property] = null;
    //         this.localStorage.deleteData(property);
    //       } else {
    //         if(property === "month" || property === "year") {
    //           this.filters[property] = val => val == rule;
    //         } else {
    //           this.filters[property] = val => val.id == rule;
    //         }
    //         // console.log(this.filters);
    //       }
    //     }
    //     // console.log(item[key], item[key]);
    //   }
    //   console.log("Updated Filters -- ", this.filters);
    // })

    // this.applyFilters();
  }

  private applyFilters() {
    // var queryString = this.changeObj.keys(params).map(key => key + '=' + params[key]).join('&');
    // console.log("FILTERS: ",this.filters);
    this.dashboardService.loadDashboards('', 'id', 'desc', this.pageIndex, this.pageSize, this.changeObj).subscribe(
      (res) => {
        // console.log(res);
        this.filteredDashboards = res['data'];
        this.length = res['meta']['total'];
        if(this.filteredDashboards.length <= this.pageSize) this.pageIndex = 0;
        this.paged();
      }
    );
    // this.filteredDashboards = _.filter(this.dashboards, _.conforms(this.filters) );
    // console.log("new filteredDashboards", this.filteredDashboards);
    // this.paged();
    // // if(!this.pagedList){
    // //   this.pagedList = this.dashboards;
    // // }
    // console.log("pagedList", this.pagedList);
  }

  /// filter property by equality to rule
  filterExact(property: string, rule: any) {
    console.log(property, rule);
    if(!rule) {
      // delete this.filters[property];
      delete this.changeObj[property];
      this[property] = null;
      this.localStorage.deleteData(property);
    } else {
      // this.filters[property] = val => val.id == rule;
      // this.changeArr.push({[property]:rule});
      this.changeObj[property] = rule;
      sessionStorage.setItem(property, rule);
    }
    // console.log(this.filters);
    // console.log("CHANGE ARR: ",this.changeArr);
    console.log("CHANGE OBJECT: ",this.changeObj);
    this.applyFilters();
  }

  /// filter property by equality to rule
  filterYearMonth(property: string, rule: any) {
    // console.log(property, rule);
    if(!rule) {
      // delete this.filters[property];
      delete this.changeObj[property];
      this[property] = null;
      this.localStorage.deleteData(property);
    } else {
      // this.filters[property] = val => val == rule;
      this.changeObj[property] = rule;
      sessionStorage.setItem(property, rule);
    }
    // console.log(this.filters);
    this.applyFilters();
  }

  /// filter  numbers greater than rule
  filterGreaterThan(property: string, rule: number) {
    // this.filters[property] = val => val > rule;
    this.applyFilters();
  }

  /// filter properties that resolve to true
  filterBoolean(property: string, rule: boolean) {
    if (!rule) this.removeFilter(property);
    else {
      // this.filters[property] = val => val;
      this.applyFilters();
    }
  }

  /// removes filter
  removeFilter(property: string) {
    // delete this.filters[property];
    this[property] = null;
    this.applyFilters();
  }

  // getDashboards(): void {
  //   // this.dashboardService.getDashboards().subscribe(
  //   this.dashboardService.loadDashboards('', 'id', 'desc', 0, 2, this.changeArr).subscribe(
  //     (res: Dashboard[]) => {
  //       console.log(res);        
  //       this.dashboards = res['data'];
  //       this.filteredDashboards = res['data'];
  //       this.length = res['meta']['total'];
  //       // console.log(this.length);
  //       // this.paged();
  //       this.applyFilters();
  //       console.log("filteredDashboards",this.filteredDashboards);
  //     }
  //   )
  // }

  getClients(): void {
    this.clientServics.getClients().subscribe(
      (res: Client[]) => {
        // console.log(res);        
        this.clients = res['data'];
        // console.log(this.dashboards);
      }
    )
  }

  getCountries(): void {
    this.countryService.getCountries().subscribe(
      (res: Country[]) => {
        // console.log(res);
        this.countries = res['data'];
        // console.log(this.countries);
      }
    )
  }

  OnPageChange(event: PageEvent){
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    let startIndex = event.pageIndex * event.pageSize;
    let endIndex = startIndex + event.pageSize;
    if(endIndex > this.length){
      endIndex = this.length;
    }

    console.log("pageSize: ",event.pageSize);
    console.log("pageIndex: ",event.pageIndex);
    // console.log("pageSize: ",event.pageSize);
    // this.pagedList = this.filteredDashboards.slice(startIndex, endIndex);

    this.applyFilters();
  }

  onResize(event) { //to adjust to screen size
    this.breakpoint = (event.target.innerWidth <= 800) ? 1 : 3;
  }

  paged() {
    this.pagedList = this.filteredDashboards.slice(0, this.pageSize);
    // this.length = this.filteredDashboards.length;
  }

  // onSelect(id: number) {
  //   console.log("selected client", id);
  //   if(id){
  //     this.filteredDashboards = this.clients.find(client => client.id == id).dashboards;
  //     this.pagedList = this.filteredDashboards.slice(0, 3);
  //     this.length = this.filteredDashboards.length;
  //   } else {
  //     this.filteredDashboards = this.dashboards;
  //     this.pagedList = this.filteredDashboards.slice(0, 3);
  //     this.length = this.filteredDashboards.length;
  //   }
  //   console.log(this.filteredDashboards);
  // }
  
  addDashboard() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('no-scroll');
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'menu-reveal';
    const addDashboard = this.dialog.open(CreateDashboardComponent, dialogConfig);
    addDashboard.afterClosed().subscribe(
      () => {
        const body = document.getElementsByTagName('body')[0];
        body.classList.remove('no-scroll');
        // this.dashboardService.getDashboards().subscribe(
        //   (res) => {
        //     this.dashboards = res['data'];
        //     console.log("updated dashboards",this.dashboards);
        //     this.applyFilters();
        //   }
        // )
        this.applyFilters();
      }
    )
  }

  dashboardDetail(dashboard) {
    // console.log(dashboard);
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('no-scroll');
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'menu-reveal';
    dialogConfig.data = { dashboard: dashboard };
    const dashboardDetail = this.dialog.open(AdminDashboardDetailComponent, dialogConfig);
    dashboardDetail.afterClosed().subscribe(
      () => {
        const body = document.getElementsByTagName('body')[0];
        body.classList.remove('no-scroll');
        // this.dashboardService.getDashboards().subscribe(
        //   (res) => {
        //     this.dashboards = res['data'];
        //     console.log("updated dashboards",this.dashboards);
        //     this.applyFilters();
        //   }
        // )
        this.applyFilters();
      }
    )
  }

  mobileFilter() {
    // console.log(dashboard);
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('no-scroll');
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'menu-reveal';
    dialogConfig.data = { clients: this.clients, countries: this.countries, months: this.months, years: this.years };
    const mobileFilter = this.dialog.open(DashboardFilterComponent, dialogConfig);
    mobileFilter.afterClosed().subscribe(
      (data) => {
        console.log("Mobile Filters: ",data);
        // this.filters = data;
        this.changeObj = data;
        const body = document.getElementsByTagName('body')[0];
        body.classList.remove('no-scroll');
        this.applyFilters();
        // this.dashboardService.getDashboards().subscribe(
        //   (res) => {
        //     this.dashboards = res['data'];
        //     console.log("updated dashboards",this.dashboards);
        //     this.applyFilters();
        //   }
        // )
      }
    )
  }

  dashboardPreview(dashboard) {
    window.open(`dashboards/${dashboard.slug}`, '_blank');
  }

  ngOnDestroy(): void {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('dashboard');
  }

}
