import { Component, OnInit, OnDestroy } from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
// import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { PageEvent, MatDialogConfig, MatDialog } from '@angular/material';
// import { DashboardService } from '../../../services/dashboard.service';

import { User, Dashboard, Client, Country } from '../../../shared/models/api.model';
import { UserService } from '../../../services/user.service';
import { ClientService } from '../../../services/client.service';
import { CountryService } from '../../../services/country.service';
import { LocalStoreManager } from '../../../services/local-store-manager.service';
import { KVComparator } from '../../../shared/utils/utils';

import { Observable } from 'rxjs';
import * as _ from 'lodash';
import { AuthService } from '../../../services/auth.service';
import { map } from 'rxjs/operators';
import { DashboardFilterComponent } from '../../dashboard-filter/dashboard-filter.component';

@Component({
  selector: 'app-dashboards',
  templateUrl: './dashboards.component.html',
  styleUrls: ['./dashboards.component.css']
})
export class DashboardsComponent implements OnInit, OnDestroy {

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );

  userId = this.localStorage.getData('id');
  user$: Observable<User>;
  multipleClients: boolean = false;
  multipleCountries: boolean = false;
  multipleTeams: boolean = false;

  dashboards: any = [];
  clients: any = [];
  countries: any = [];
  filteredDashboards: any = [];

  pagedList: Dashboard[] = [];
  breakpoint: number = 10;  //to adjust to screen
  // MatPaginator Inputs
  length: number = 0;
  pageSize: number = 20;  //displaying three cards each row
  pageSizeOptions: number[] = [20, 40, 60, 100];

  
  f_array = [];
  filters = {};
  
  /// Active filter rules
  client = this._utils.JsonTryParseReturnNumber(sessionStorage.getItem('client_id'));
  country = this._utils.JsonTryParseReturnNumber(sessionStorage.getItem('country_id'));
  month = this._utils.JsonTryParseReturnNumber(sessionStorage.getItem('month'));
  year = this._utils.JsonTryParseReturnNumber(sessionStorage.getItem('year'));

  months: any = [
    {id:1, name:'January'},
    {id:2, name:'February'},
    {id:3, name:'March'},
    {id:4, name:'April'},
    {id:5, name:'May'},
    {id:6, name:'June'},
    {id:7, name:'July'},
    {id:8, name:'August'},
    {id:9, name:'September'},
    {id:10, name:'October'},
    {id:11, name:'November'},
    {id:12, name:'December'}
  ];

  years: any = [
    {id:2015, name:2015},
    {id:2016, name:2016},
    {id:2017, name:2017},
    {id:2018, name:2018},
    {id:2019, name:2019},
    {id:2020, name:2020},
    {id:2021, name:2021},
    {id:2022, name:2022},
    {id:2023, name:2023}
  ];

  constructor(
    private localStorage: LocalStoreManager,
    private auth: AuthService,
    private userService: UserService,
    private breakpointObserver: BreakpointObserver,
    private clientServics: ClientService,
    private countryService: CountryService,
    private router: Router,
    private dialog: MatDialog,
    private _utils: KVComparator
  ) {
    this.auth.checkAuth();
    // this.auth.authStatus.subscribe(
    //   (value) => console.log("AUTH STATUS: ",value)
    // );
  }

  ngOnInit() {
    this.setFilters();
    // console.log("User ID", this.userId);
    // console.log("CUR USER", this.auth.getCurrentUser());
    // this.filters[property] = val => val == rule;

    this.getUser();
    // this.getClients();
    // this.getCountries();
    // console.log(this.pageSize);
    // console.log("MONTHS", this.months);

    this.breakpoint = (window.innerWidth <= 800) ? 1 : 3;

    const body = document.getElementsByTagName('body')[0];
    body.classList.add('dashboard','client');

    // window.addEventListener('storage', (event) => {
    //   if (event.storageArea === sessionStorage) {
    //     let token = this.localStorage.getData('accessToken');
    //     alert(token);
    //     if(token == undefined) { // you can update this as per your key
    //       // DO LOGOUT FROM THIS TAB AS WELL
    //       // this.router.navigate(['login']); // If you are using router
    //       // OR
    //       // window.location.href = '<home page URL>';
    //     }
    //   }
    // });
  }

  // compareObjects(o1: any, o2: any): boolean {
  //   return o1.name === o2.name && o1.id === o2.id;
  // }

  setFilters() {
    if(this.client) this.f_array.push({"client_id":this.client});
    if(this.country) this.f_array.push({"country_id":this.country});
    if(this.month) this.f_array.push({"month":this.month});
    if(this.year) this.f_array.push({"year":this.year});

    // console.log(this.f_array);

    // for(let key in this.f_array){
    //   console.log(this.f_array[key], this.f_array[key].val);
    // }

    this.f_array.forEach(item => {
      for(let key in item){
        if(item.hasOwnProperty(key)){
          // console.log(key, item[key])
          let property: string = key;
          let rule: number = item[key];
          this.filters[property] = val => val == rule;
          // console.log(this.filters);
        }
        // console.log(item[key], item[key]);
      }
    })

    // this.applyFilters();
  }

  getUser() {
    this.userService.getUser(this.userId).subscribe(
      (res: User) => {
        this.user$ = res['data'];
        this.dashboards = this.user$['dashboards'];
        this.multipleClient();
        this.multipleCountry();
        this.multipleTeam();
        this.filteredDashboards = this.dashboards;
        // this.countries = this.dashboards.map(data => data.country_id).filter((x, i, a) => x && a.indexOf(x) === i);
        // this.countries = this.dashboards.country;
        // this.paged();
        this.applyFilters();
        // console.log("userDashboard", this.dashboards);
      }
    )
  }

  multipleClient(){
    const c = this.dashboards.map(data => data.client)
    .filter((x, i, a) => x && a.indexOf(x) === i)
    .filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj.id).indexOf(obj.id) === pos;
    });
    this.clients = c;
    if(c.length > 1) {
      this.multipleClients = true;
    }
  }

  multipleCountry(){
    const c = this.dashboards.map(data => data.country)
    .filter((x, i, a) => x && a.indexOf(x) === i)
    .filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj.id).indexOf(obj.id) === pos;
    });
    this.countries = c;
    if(c.length > 1) {
      this.multipleCountries = true;
    }
  }

  multipleTeam(){
    const t = this.dashboards.map(data => data.team_id)
    .filter((x, i, a) => x && a.indexOf(x) === i).length;
    if(t > 1) {
      this.multipleTeams = true;
    }
  }

  getClients(): void {
    this.clientServics.getClients().subscribe(
      (res: Client[]) => {
        // console.log(res);
        this.clients = res['data'];
        // console.log(this.dashboards);
      }
    )
  }

  getCountries(): void {
    this.countryService.getCountries().subscribe(
      (res: Country[]) => {
        // console.log(res);
        this.countries = res['data'];
        // console.log(this.dashboards);
      }
    )
  }

  private applyFilters() {
    this.auth.checkAuth();
    // console.log(this.filters);
    // localStorage.setItem("f_i", this.filters);
    this.filteredDashboards = _.filter(this.dashboards, _.conforms(this.filters) );
    // console.log("new filteredDashboards", this.filteredDashboards);
    this.paged();
    // console.log("pagedList", this.pagedList)
  }

  /// filter property by equality to rule
  filterExact(property: string, rule: any) {
    // console.log(property, rule);
    if(!rule) {
      delete this.filters[property];
      this[property] = null;
      this.localStorage.deleteData(property);
    } else {
      this.filters[property] = val => val == rule;
      sessionStorage.setItem(property, rule);
    }
    // console.log(this.filters);
    this.applyFilters();
  }

  /// filter property by equality to rule
  filterYearMonth(property: string, rule: any) {
    // console.log(property, rule);
    if(!rule) {
      delete this.filters[property];
      this[property] = null;
      this.localStorage.deleteData(property);
    } else {
      this.filters[property] = val => val == rule;
      sessionStorage.setItem(property, rule);
    }
    // console.log(this.filters);
    this.applyFilters();
  }

  /// filter  numbers greater than rule
  filterGreaterThan(property: string, rule: number) {
    this.filters[property] = val => val > rule;
    this.applyFilters();
  }

  /// filter properties that resolve to true
  filterBoolean(property: string, rule: boolean) {
    if (!rule) this.removeFilter(property)
    else {
      this.filters[property] = val => val;
      this.applyFilters();
    }
  }

  /// removes filter
  removeFilter(property: string) {
    delete this.filters[property];
    this[property] = null;
    this.applyFilters();
  }

  OnPageChange(event: PageEvent){
    // console.log(event.pageSize);
    this.pageSize = event.pageSize;
    let startIndex = event.pageIndex * event.pageSize;
    let endIndex = startIndex + event.pageSize;
    if(endIndex > this.length){
      endIndex = this.length;
    }
    this.pagedList = this.filteredDashboards.slice(startIndex, endIndex);
  }

  onResize(event) { //to adjust to screen size
    this.breakpoint = (event.target.innerWidth <= 800) ? 1 : 3;
  }

  paged() {
    this.pagedList = this.filteredDashboards.slice(0, this.pageSize);
    this.length = this.filteredDashboards.length;
  }

  dashboardDetail(dashboard: Dashboard) {
    this.auth.checkAuth().subscribe(
      (val) => {
        (val === true) ? this.router.navigate(['dashboards', dashboard.slug]) : this.router.navigate(['login']);
      }
    )
  }

  mobileFilter() {
    // console.log(dashboard);
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('no-scroll');
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'menu-reveal';
    dialogConfig.data = { clients: this.clients, countries: this.countries, months: this.months, years: this.years };
    const mobileFilter = this.dialog.open(DashboardFilterComponent, dialogConfig);
    mobileFilter.afterClosed().subscribe(
      (data) => {
        this.filters = data;
        const body = document.getElementsByTagName('body')[0];
        body.classList.remove('no-scroll');
        this.applyFilters();
        // this.dashboardService.getDashboards().subscribe(
        //   (res) => {
        //     this.dashboards = res['data'];
        //     console.log("updated dashboards",this.dashboards);
        //     this.applyFilters();
        //   }
        // )
      }
    )
  }

  ngOnDestroy(): void {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('dashboard','client');
  }

}
