import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';

import { TokenService } from './token.service';
import { LocalStoreManager } from './local-store-manager.service';

import { BehaviorSubject, Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private _currentUser: BehaviorSubject<string>;

  // userRoles: string;

  private loggedIn = new BehaviorSubject <boolean>(this.token.loggedIn());
  authStatus = this.loggedIn.asObservable();

  changeAuthStatus(value : boolean) {
    // console.log("AS--------------", value);
    this.loggedIn.next(value);
  }

  roleMatch(allowedRoles): boolean {
    var isMatch = false;
    // var userRoles: string[] = JSON.parse(localStorage.getItem('ur'));
    // var userRoles: string = localStorage.getItem('ur');
    if(this.checkAuth) {
      var userRoles = this.localStorage.getData('ur');
      // console.log("USER ROLE", userRoles);
      if(userRoles == null){
        this.localStorage.clearAllSessionsStorage();
        this.router.navigate(['login']);
        return false;
      } else {
        allowedRoles.forEach(element => {
          // console.log(element);
          if (userRoles.indexOf(element) > -1) {
            isMatch = true;
            return false;
          }
        });
      }
    }
    return isMatch;
  }

  constructor(
    private localStorage: LocalStoreManager,
    private http : HttpClient,
    private router : Router,
    private dialogRef : MatDialog,
    private token : TokenService
  ) {
    this._currentUser = new BehaviorSubject("");
    // console.log("IS AUTHENTICATED",this.localStorage.getData('ur'))
  }

  // isAuthenticated(): Promise<boolean> {
  //   return Promise.resolve(!!localStorage.getItem('token'));
  // }

  getCurrentUser(): Observable<string> {
    return this._currentUser.asObservable();
  }

  setUser(user: string) {
    this._currentUser.next(user);
  }

  refreshAccessToken() {
    return this.http.post(`/refresh`, {
      'token': this.token.getToken()
    })
    .pipe(
      tap((data) => {
        // console.log("DATATATATATATATATAATATA",data);
        // localStorage.setItem('token',data['token']);
        this.localStorage.saveSyncedSessionData('accessToken',data['token']);
      })
    );
  }

  getUserId(): Promise<any> {
    return Promise.resolve(this.localStorage.getData('id'));
  }

  getFullName(): String {
    return `${this.localStorage.getData('fn')} ${this.localStorage.getData('ln')}`;
  }

  logout() {
    const token = this.token.getToken();
    if(!token) {
      this.changeAuthStatus(false);
      this.localStorage.clearAllSessionsStorage();
      this.router.navigate(['login']);
    } else {
      return this.http.post('/logout', {
        'token': token
      })
      .pipe(
        tap((data) => {
          // console.log(data);
          this.changeAuthStatus(false);
          this.dialogRef.closeAll();
          // this.token.destroy();
          this.localStorage.clearAllSessionsStorage();
          // localStorage.removeItem('ng2Idle.main.expiry');
          // localStorage.setItem('ng2Idle.main.idling', 'false');
          // localStorage.removeItem('c_user');
          // this.router.navigateByUrl('/login');
          this.router.navigate(['login']);
        })
      )
    }
  }

  checkAuth(): Observable<any> {
    // console.log("Check auth");
    const isLoggedIn = this.token.isValid();
    if(isLoggedIn) {
      // console.log("LoggedIN");
      this.changeAuthStatus(true);
      this.setUser(String(this.getFullName()));
      return of(true);
    } else {
      // console.log("Not LoggedIN");
      this.localStorage.clearAllStorage();
      this.router.navigate(['login']);
      return of(false);
    }
  }
  
}
