import { Component, OnInit, Inject, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { DashboardService } from '../../../../services/dashboard.service';
// import { TokenService } from '../../../../services/token.service';
import { UserService } from '../../../../services/user.service';
// import { Router } from '@angular/router';
import { Observable, fromEvent } from 'rxjs';
import { User, Dashboard} from '../../../../shared/models/api.model';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material';
// import { SlicePipe } from '@angular/common';
import { map, debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';

@Component({
  selector: 'app-add-user-dashboard',
  templateUrl: './add-user-dashboard.component.html',
  styleUrls: ['./add-user-dashboard.component.css']
})
export class AddUserDashboardComponent implements OnInit {

  pageTitle = "Add User Dashboard";
  dashboards: any = [];
  curDashboards: any = [];
  filteredDashboards: any = [];
  fDashboards: any = [];
  user: any = [];
  assignDashboardForm: FormGroup;

  hide: any = {};
  public error = [];

  constructor(
    private title: Title,
    private formBuilder: FormBuilder,
    private dashboardService: DashboardService,
    private userService: UserService,
    private dialogRef: MatDialogRef<AddUserDashboardComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // console.log("user data", data);
    this.user = data.user;
    this.data.user.dashboards.forEach((dashboard: any) => {
      this.curDashboards.push(dashboard.id);
    });
    console.log(this.curDashboards);
  }

  @ViewChild('dashboardSearch') input: ElementRef;

  ngOnInit() {
    this.title.setTitle(this.pageTitle);
    this.getDashboards();
    this.assignDashboardForm = this.formBuilder.group({
      id: this.user.id,
      dashboards: this.formBuilder.group([])
    });

    this.userDashboardControls();
  }

  ngAfterViewInit() {
    fromEvent(this.input.nativeElement, 'keyup')
    .pipe(
      debounceTime(150),
      distinctUntilChanged(),
      tap(() => {
        this.filterDashboards();
        // console.log("Filtered: ", this.fDashboards);
      })
    )
    .subscribe();

  }

  getDashboards() {
    this.dashboardService.allDashboardTitles().subscribe(
      (res: any) => {
        res['data'].forEach((item) => {
          item.shown = true;
          this.dashboards.push(item);
        })
        // this.dashboards = res['data'];
        this.filteredDashboards = this.dashboards;
        // this.dashboards.sort((a,b) => a.name.localeCompare(b.name));
        console.log(this.dashboards);
        // console.log("Filtered: ", this.filteredDashboards);
        // this.dashboards.forEach((dashboard: any) => {
        //   const dashboards = <FormGroup>this.assignDashboardForm.get('dashboards');
        //   dashboards.addControl(dashboard.id, new FormControl(false));
        // });
      }
    )
  }

  filterDashboards() {
    // console.log("Form:", this.assignDashboardForm.getRawValue().dashboards);
    var searchTerm = this.input.nativeElement.value;
    if(!searchTerm){
      this.fDashboards = [];
    }
    // this.filteredDashboards = this.dashboards.filter(
    //   item => item.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
    // )
    
    this.dashboards.filter(
      (item) => {
        let m: boolean = item.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
        // console.log("BOOL: ", m);
        (m) ? item.shown = true : item.shown = false;
      }
    );

  }

  userDashboardControls() {
    setTimeout(() => {
      // this.dashboards.sort((a,b) => a.name.localeCompare(b.name));
      this.filteredDashboards.forEach((dashboard: any) => {
        const dashboards = <FormGroup>this.assignDashboardForm.get('dashboards');
        let dDisabled: boolean;
        (this.curDashboards.includes(dashboard.id)) ? dDisabled = true : dDisabled = false;
        dashboards.addControl(dashboard.id, new FormControl({value: false, disabled: dDisabled}));
      });
    }, 1000);
  }

  onSubmit(){
    // console.log(this.assignUserForm.value);
    var dashboardsObj = this.assignDashboardForm.value.dashboards;
    var selectedDashboardIds = [];
    for (var dashboardId in dashboardsObj) {
      if (dashboardsObj.hasOwnProperty(dashboardId)) {
        if(dashboardsObj[dashboardId])//If selected
        {
          selectedDashboardIds.push(dashboardId);
        }
      }
    }

    let data = {
      id: this.assignDashboardForm.value.id,
      dashboards: selectedDashboardIds
    }
    console.log(data);

    this.userService.addUserDashboard(data).subscribe(
      data => this.handleResponse(data),
      error => this.handleError(error)
    )
  }

  handleResponse(data) {
    console.log(data);
    this.dialogRef.close();
    // this.router.navigateByUrl('/admin/dashboards');
  }

  handleError(error) {
    this.error = error.error.errors;
  }

  closeBtn(): void{
    this.dialogRef.close();
  }

}
