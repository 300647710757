import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../../core/core.module';

// import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
// import { AdminRoutingModule } from './admin-routing.module';
// import { CreatePostComponent } from './create-post/create-post.component';
// import { CategoryComponent } from './category/category.component';
// import { MediaComponent } from './media/media.component';
import { CreateDashboardComponent } from './dashboards/create-dashboard/create-dashboard.component';
import { UsersListComponent } from './users/users-list/users-list.component';
import { DashboardsListComponent } from './dashboards/dashboards-list/dashboards-list.component';
import { CreateClientComponent } from './clients/create-client/create-client.component';
import { ClientsListComponent } from './clients/clients-list/clients-list.component';
import { AddTeamComponent } from './teams/add-team/add-team.component';
import { TeamsListComponent } from './teams/teams-list/teams-list.component';
import { AddPermissionComponent } from './permissions/add-permission/add-permission.component';
import { PermissionsListComponent } from './permissions/permissions-list/permissions-list.component';
import { AddRoleComponent } from './roles/add-role/add-role.component';
import { RolesListComponent } from './roles/roles-list/roles-list.component';
import { AddUserComponent } from './users/add-user/add-user.component';
import { AdminComponent } from './admin.component';
import { EditClientComponent } from './clients/edit-client/edit-client.component';
import { EditDashboardComponent } from './dashboards/edit-dashboard/edit-dashboard.component';
import { AssignUserComponent } from './dashboards/assign-user/assign-user.component';
import { DashboardUsersListComponent } from './dashboards/dashboard-users-list/dashboard-users-list.component';
import { AddDashboardUserComponent } from './dashboards/add-dashboard-user/add-dashboard-user.component';
import { EditUserComponent } from './users/edit-user/edit-user.component';
import { AdminDashboardDetailComponent } from './dashboards/admin-dashboard-detail/admin-dashboard-detail.component';
import { UserDetailComponent } from './users/user-detail/user-detail.component';
import { UserDashboardListComponent } from './users/user-dashboard-list/user-dashboard-list.component';
import { AddUserDashboardComponent } from './users/add-user-dashboard/add-user-dashboard.component';
import { TeamDetailComponent } from './teams/team-detail/team-detail.component';
import { EditTeamComponent } from './teams/edit-team/edit-team.component';
import { TeamClientListComponent } from './teams/team-client-list/team-client-list.component';
import { TeamClientCountryListComponent } from './teams/team-client-country-list/team-client-country-list.component';
import { ClientDetailComponent } from './clients/client-detail/client-detail.component';
import { ClientCountriesListComponent } from './clients/client-countries-list/client-countries-list.component';
import { AddClientCountryComponent } from './clients/add-client-country/add-client-country.component';
import { MaterialModule } from '../../material.module';
// import { AdminSideNavTogglerComponent } from './admin-side-nav-toggler/admin-side-nav-toggler.component';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    MaterialModule,
    // AdminRoutingModule
    // FontAwesomeModule
  ],
  declarations: [
    // CreatePostComponent,
    // CategoryComponent,
    // MediaComponent,
    CreateDashboardComponent,
    UsersListComponent,
    DashboardsListComponent,
    CreateClientComponent,
    ClientsListComponent,
    AddTeamComponent,
    TeamsListComponent,
    AddPermissionComponent,
    PermissionsListComponent,
    AddRoleComponent,
    RolesListComponent,
    AddUserComponent,
    AdminComponent,
    EditClientComponent,
    EditDashboardComponent,
    AssignUserComponent,
    DashboardUsersListComponent,
    AddDashboardUserComponent,
    EditUserComponent,
    AdminDashboardDetailComponent,
    UserDetailComponent,
    UserDashboardListComponent,
    AddUserDashboardComponent,
    TeamDetailComponent,
    EditTeamComponent,
    TeamClientListComponent,
    TeamClientCountryListComponent,
    ClientDetailComponent,
    ClientCountriesListComponent,
    AddClientCountryComponent,
    // AdminSideNavTogglerComponent,
  ],
  entryComponents: [
    // MediaComponent,
    CreateClientComponent,
    EditClientComponent,
    CreateDashboardComponent,
    EditDashboardComponent,
    AssignUserComponent,
    DashboardUsersListComponent,
    AddDashboardUserComponent,
    AddUserComponent,
    EditUserComponent,
    AdminDashboardDetailComponent,
    UserDetailComponent,
    UserDashboardListComponent,
    AddUserDashboardComponent,
    AddTeamComponent,
    TeamDetailComponent,
    ClientDetailComponent,
    ClientCountriesListComponent,
    AddClientCountryComponent,
    EditClientComponent,
    TeamClientCountryListComponent,
    TeamsListComponent
  ]
})
export class AdminModule { }
