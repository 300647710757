import { Component, OnInit, Inject, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material';
import { Dashboard } from '../../../../shared/models/api.model';
import { UserService } from '../../../../services/user.service';
import { Observable, fromEvent } from 'rxjs';
import { AddUserDashboardComponent } from '../add-user-dashboard/add-user-dashboard.component';
import { map, debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';

@Component({
  selector: 'app-user-dashboard-list',
  templateUrl: './user-dashboard-list.component.html',
  styleUrls: ['./user-dashboard-list.component.css']
})
export class UserDashboardListComponent implements OnInit {

  pageTitle = "User Dashboards";
  hide: any = {};
  // user$: Observable<Dashboard>;
  user$: any = [];
  userDashboards;
  // dashboard: any = [];
  test;

  public error = [];

  constructor(
    private title: Title,
    private userService: UserService,
    private router: Router,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<UserDashboardListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // this.dashboard$ = data.dashboard
    // this.userDashboards = data.user.dashboards;
    // for(let i = 0; i < this.userDashboards.length; i++){
    //   this.userDashboards[i].shown = true;
    // }
    // this.user$ = data.user
    // console.log(this.user$);

    this.userData(data.user);
  }

  @ViewChild('dashboardSearch') input: ElementRef;

  // postDetail(dashboard: Dashboard) {
  //   console.log(dashboard);
  //   // this.router.navigate(['dashboards', dashboard.slug]);
  // }

  ngOnInit() {
    this.title.setTitle(this.pageTitle);
    // this.getDashboards();
  }

  ngAfterViewInit() {
    fromEvent(this.input.nativeElement, 'keyup')
    .pipe(
      debounceTime(150),
      distinctUntilChanged(),
      tap(() => {
        this.filterDashboards();
        // console.log("Filtered: ", this.fDashboards);
      })
    )
    .subscribe();

  }

  userData(user:any){
    let userDashboards = user.dashboards;
    for(let i = 0; i < userDashboards.length; i++){
      userDashboards[i].shown = true;
    }
    this.user$ = user
    // console.log("TEST! ",user);
  }

  filterDashboards() {
    var searchTerm = this.input.nativeElement.value;
    
    this.user$.dashboards.filter(
      (item) => {
        let m: boolean = item.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
        // console.log("BOOL: ", m);
        (m) ? item.shown = true : item.shown = false;
      }
    );

  }

  assignDashboard() {
    // console.log("clicked");
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'menu-reveal';
    dialogConfig.data = { user: this.user$ };
    const addUser = this.dialog.open(AddUserDashboardComponent, dialogConfig);
    addUser.afterClosed().subscribe(
      () => {
        this.userService.getUser(this.user$['id']).subscribe(
          (res) => {
            // this.user$ = res['data'];
            this.userData(res['data']);
            console.log("new user",this.user$);
          }
        )
      }
    )
  }

  removeUserDashboard(dashboard) {
    let data = {
      id: this.user$['id'],
      dashboard: dashboard.id
    }
    // console.log(data);

    this.userService.removeUserDashboard(data).subscribe(
      (data) => {
        this.handleResponse(data);
        if(data) {
          this.hide[dashboard.id] = !this.hide[dashboard.id]
        }
      },
      error => this.handleError(error)
    )
  }

  handleResponse(data) {
    console.log(data);
    // this.router.navigateByUrl('/admin/dashboards');
  }

  handleError(error) {
    this.error = error.error.errors;
  }

  closeBtn(): void{
    this.dialogRef.close();
  }

}
