import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Dashboard } from '../shared/models/api.model';
import { Observable } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(
    private http: HttpClient
  ) { }

  // Add Dashboard
  createDashboard(data){
    return this.http.post(`/dashboard`, data);
  }

  // Get Dashboard
  getDashboards(): Observable<Dashboard[]>{
    return this.http.get<Dashboard[]>(`/dashboards`);
  }

  // Get Dashboard
  getDashboard(slug): Observable<Dashboard> {
    return this.http.get<Dashboard>(`/dashboard/`+slug);
    // .pipe(
    //   tap(_ => console.log(`fetched post id=${id}`))
    //   // catchError(this.handleError<Post>(`getPost id=${id}`))
    // );
  }

  // Edit Dashboard
  editDashboard(dashboard: Dashboard) {
    return this.http.put(`/dashboard`, dashboard);
  }

  // Add Dashboard User(s)
  addDashboardUser(data){
    return this.http.post(`/dashboard/add-users`, data);
  }

  // Add Dashboard User(s)
  removeDashboardUser(data){
    return this.http.post(`/dashboard/remove-users`, data);
  }

  // Toggle Dashboard Status
  dashboardStatus(data) {
    return this.http.post(`/dashboard/dashboard-status`, data);
  }

  // Dashboards List
  loadDashboards(filter, sortBy, sortOrder, pageNumber, pageSize, q): Observable<Dashboard[]> {
    if(sortBy == '') sortBy = "id";
    // console.log("QUERY", q);
    // this.getParams(q);

    let httpParams = new HttpParams()
    .set('q', filter)
    .set('sortBy', sortBy)
    .set('orderBy', sortOrder)
    .set('page', pageNumber + 1)
    .set('pageSize', pageSize.toString());

    for (const key in q) {
      if (q.hasOwnProperty(key)) {
          // for(const subKey in q[key]) {
              // if (q[key].hasOwnProperty(subKey)) {
                httpParams = httpParams.append(key, q[key]);
              // }
          // }
      }
  }

    // console.log("PARAMS: ", httpParams);

    return this.http.get('/dashboards-list', {
      params: httpParams
      // params: new HttpParams()
      //   .set('q', filter)
      //   .set('sortBy', sortBy)
      //   .set('orderBy', sortOrder)
      //   .set('page', pageNumber + 1)
      //   .set('pageSize', pageSize.toString())
    })
    .pipe(
      map((dashboards:Dashboard[]) => {
        // console.log (dashboards);
        return dashboards;
      })
    );
  }

  allDashboardTitles() {
    return this.http.get('/dashboard-titles');
  }


  private getParams(query) {
    let params: HttpParams = new HttpParams();
    for (const key of Object.keys(query)) {
      if (query[key]) {
        if (query[key] instanceof Array) {
          query[key].forEach((item) => {
            params = params.append(`${key.toString()}[]`, item);
          });
        } else {
          params = params.append(key.toString(), query[key]);
        }
      }
    }
    // console.log(params);
    return params;
  }

}
