import { Component, OnInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray, FormGroupDirective, NgForm } from '@angular/forms';
import { UserService } from '../../../services/user.service';
import { SnotifyService } from 'ng-snotify';

@Component({
  selector: 'app-request-reset',
  templateUrl: './request-reset.component.html',
  styleUrls: ['./request-reset.component.css']
})
export class RequestResetComponent implements OnInit, OnDestroy {
  pageTitle = "Reset Password";
  hide = true;

  passwordForm: FormGroup;


  public error: any = [];
  public form = {
    email: null
  }

  constructor(
    private title: Title,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private notify: SnotifyService
  ) { }

  ngOnInit() {
    this.title.setTitle(this.pageTitle);

    const body = document.getElementsByTagName('body')[0];
    body.classList.add('dashboard','client','p_r');

    this.passwordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      });
  }

  get f() { return this.passwordForm.controls; }

  onSubmit() {
    this.notify.info('Loading...', {timeout:3000});
    // this.userService.passwordReset(this.form).subscribe(
    //   data => this.handleresponse(data),
    //   error => this.notify.error(error.error.error)
    // )
    const formValue = this.passwordForm.value
    // console.log(formValue);
    this.userService.passwordReset(formValue).subscribe(
      data => this.handleResponse(data),
      error => this.notify.error(error.error.message)
    )
  }

  handleResponse(res) {
    this.notify.success(res.message, {timeout:0});
    this.form.email = null;
  }

  ngOnDestroy(): void {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('dashboard','client','p_r');
  }

}
