import { Component, OnInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray, FormGroupDirective, NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../../services/user.service';
import { SnotifyService } from 'ng-snotify';
import { ErrorStateMatcher } from '@angular/material/core';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);

    return (invalidCtrl || invalidParent);
  }
}

@Component({
  selector: 'app-response-reset',
  templateUrl: './response-reset.component.html',
  styleUrls: ['./response-reset.component.css']
})
export class ResponseResetComponent implements OnInit, OnDestroy {

  pageTitle = "Reset Password";
  curPage: string;
  hide = true;

  passwordForm: FormGroup;
  matcher = new MyErrorStateMatcher();

  token: any;
  validToken: boolean = false;

  public error: any = [];

  // public form = {
  //   email: ['', Validators.required],
  //   password: ['', Validators.required],
  //   password_confirmation: ['', Validators.required],
  //   // resetToken: null
  //   token: null
  // }

  constructor(
    private title: Title,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private userService: UserService,
    private router: Router,
    private notify: SnotifyService
  ) {
    route.queryParams.subscribe(params => {
      // console.log("PARAMS: ", params);
      this.token = params['token']
    });

    const currentPath = this.router.url;
    (currentPath.includes('create')) ? this.curPage = "create" : this.curPage = "change";
  }

  ngOnInit() {
    this.userService.checkPasswordToken(this.token).subscribe(
      (res) => {
        // console.log(res);
        this.validToken = true;
      },
      (error) => {
        // console.log(error);
        this.handleError(error)
      }
    );
    this.title.setTitle(this.pageTitle);

    const body = document.getElementsByTagName('body')[0];
    body.classList.add('dashboard','client','p_r');

    this.passwordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', 
        [
          Validators.required,
          Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&]).{8,}'),
        ]
      ],
      password_confirmation: ['', Validators.required,],
      token: this.token,
      }, {validator: this.checkPasswords});
  }

  get f() { return this.passwordForm.controls; }

  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    let pass = group.controls.password.value;
    let confirmPass = group.controls.password_confirmation.value;

    return pass === confirmPass ? null : { notSame: true }
  }

  onSubmit() {
    const formValue = this.passwordForm.value
    // console.log(formValue);
    this.userService.changePassword(formValue).subscribe(
      data => this.handleResponse(data),
      error => this.handleError(error)
    )
  }

  handleResponse(data) {
    let _router = this.router;
    this.notify.confirm('Password changed, please login with new password', {
      buttons:[
        {text: 'Okay',
        action: toaster => {
          _router.navigateByUrl('/login'),
          this.notify.remove(toaster.id)
        }
      },
      ]
    })
  }

  handleError(error) {
    this.error = error.error.errors;
  }

  ngOnDestroy(): void {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('dashboard','client','p_r');
  }

}
