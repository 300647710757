import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { PermissionService } from '../../../../services/permission.service';
import { Router } from '@angular/router';
import { SlugifyPipe } from '../../../../shared/pipes/slugify.pipe';
import { SlicePipe } from '@angular/common';
import { map, tap } from 'rxjs/operators';
import { MatDialog, MatDialogConfig } from '@angular/material';

@Component({
  selector: 'app-add-permission',
  templateUrl: './add-permission.component.html',
  styleUrls: ['./add-permission.component.css']
})
export class AddPermissionComponent implements OnInit {

  permForm: FormGroup;

  public error = [];

  constructor(
    private formBuilder: FormBuilder,
    private permService: PermissionService,
    private router : Router,
    private slugifyPipe : SlugifyPipe,
    private mediaModal : MatDialog
  ) { }

  ngOnInit() {
    this.permForm = this.formBuilder.group({
    name: ['', Validators.required],
    slug: ''
    });
    
  }

  onSubmit(){
    const formValue = this.permForm.value;
    console.log(formValue);
    

    
    // console.log(this.form);
    this.permService.createPermission(formValue).subscribe(
      data => this.handleResponse(data),
      error => this.handleError(error)
    )
  }

  get name() {
    return this.permForm.get('name');
  }

  handleResponse(data) {
    console.log(data);
    this.router.navigateByUrl('/admin/permissions');
  }

  handleError(error) {
    this.error = error.error.errors;
  }

  slugify(input: string){
    return this.slugifyPipe.transform(input);
  }

}
