import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Country } from '../shared/models/api.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CountryService {

  constructor(
    private http: HttpClient
  ) { }

  // Get Countries
  getCountries(): Observable<Country[]>{
    return this.http.get<Country[]>(`/countries`);
  }
}
