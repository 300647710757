import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
// import { KeyValue } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { CountryService } from '../../../../services/country.service';
// import { TokenService } from '../../../../services/token.service';
import { ClientService } from 'src/app/services/client.service';
// import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Client, Country} from '../../../../shared/models/api.model';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material';
import { KVComparator } from '../../../../shared/utils/utils';

@Component({
  selector: 'app-add-client-country',
  templateUrl: './add-client-country.component.html',
  styleUrls: ['./add-client-country.component.css']
})
export class AddClientCountryComponent implements OnInit {

  pageTitle = "Add Client Country";
  countries: any = [];
  curCountries: any = [];
  // filteredCountries: any = [];
  client: any = [];
  addClientCountryForm: FormGroup;

  public error = [];

  constructor(
    private title: Title,
    private formBuilder: FormBuilder,
    private countryService: CountryService,
    private clientService: ClientService,
    public kvOrder: KVComparator,
    private dialogRef: MatDialogRef<AddClientCountryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // console.log("user data", data);
    this.client = data.client;
    this.data.client.countries.forEach((country: any) => {
      this.curCountries.push(country.id);
    });
    console.log(this.curCountries);
  }

  ngOnInit() {
    this.title.setTitle(this.pageTitle);
    this.getCountries();
    this.addClientCountryForm = this.formBuilder.group({
      id: this.client.id,
      countries: this.formBuilder.group([])
    });

    this.clientCountryControls();
  }

  getCountries() {
    this.countryService.getCountries().subscribe(
      (res: any) => {
        this.countries = res['data'];
        console.log(this.countries);
        //Filter countries //
        //use filteredList of countries instead of this.countries
        //forEach inside filter function
        // this.filteredCountries = this.countries;
        // this.applyFilter("");
        // this.countries.forEach((country: any) => {
        //   const countries = <FormGroup>this.addClientCountryForm.get('countries');
        //   countries.addControl(country.id, new FormControl(false));
        // });
      }
    )
  }

  clientCountryControls() {
    setTimeout(()=>{
      this.countries.sort((a,b) => a.name.localeCompare(b.name));
      this.countries.forEach((country: any) => {
        const countries = <FormGroup>this.addClientCountryForm.get('countries');
        let cDisabled: boolean;
        (this.curCountries.includes(country.id)) ? cDisabled = true : cDisabled = false;
        countries.addControl(country.id, new FormControl({value: false, disabled: cDisabled}));
      });
    }, 1000);
  }

  onSubmit(){
    // console.log(this.assignUserForm.value);
    var countriesObj = this.addClientCountryForm.value.countries;
    var selectedCountryIds = [];
    for (var countryId in countriesObj) {
      if (countriesObj.hasOwnProperty(countryId)) {
        if(countriesObj[countryId])//If selected
        {
          selectedCountryIds.push(countryId);
          console.log(selectedCountryIds);
        }
      }
    }

    let data = {
      id: this.addClientCountryForm.value.id,
      countries: selectedCountryIds
    }
    console.log(data);

    this.clientService.addClientCountry(data).subscribe(
      data => this.handleResponse(data),
      error => this.handleError(error)
    )
  }

  // applyFilter(filterValue: string) {
  //   console.log(filterValue);
  //   this.filteredCountries.filter = filterValue.trim().toLowerCase();
  //   this.filteredCountries.forEach((country: any) => {
  //     const countries = <FormGroup>this.addClientCountryForm.get('countries');
  //     countries.addControl(country.id, new FormControl(false));
  //   });
  //   // console.log(this.filteredCountries);

  //   // return this.filteredCountries ? this.filteredCountries.filter(item => item.name.search(new RegExp(filterValue, 'i')) > -1) : [];

  //   // if (this.dataSource.paginator) {
  //   //   this.dataSource.paginator.firstPage();
  //   // }
  // }

  // applyFilter(filterValue: string) {
  //   console.log(filterValue);
  //   // this.filteredCountries.filter = filterValue.trim().toLowerCase();
  //   // this.filteredCountries.forEach((country: any) => {
  //   //   const countries = <FormGroup>this.addClientCountryForm.get('countries');
  //   //   countries.addControl(country.id, new FormControl(false));
  //   // });
  //   // console.log(this.filteredCountries);

  //   // const fc = this.filteredCountries ? this.filteredCountries.filter(item => item.name.search(new RegExp(filterValue, 'i')) > -1) : [];
  //   return this.filteredCountries ? this.filteredCountries.filter(item => item.name.search(new RegExp(filterValue, 'i')) > -1) : [];
  //   // console.log(fc);
  //   // fc.forEach((country: any) => {
  //   //   const countries = <FormGroup>this.addClientCountryForm.get('countries');
  //   //   countries.addControl(country.id, new FormControl(false));
  //   // });
  //   // if (this.dataSource.paginator) {
  //   //   this.dataSource.paginator.firstPage();
  //   // }
  // }

  handleResponse(data) {
    console.log(data);
    this.dialogRef.close();
    // this.router.navigateByUrl('/admin/dashboards');
  }

  handleError(error) {
    this.error = error.error.errors;
  }

  closeBtn(): void{
    this.dialogRef.close();
  }

}
