import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogRef, MatDialog, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material';
import { LocalStoreManager } from '../../services/local-store-manager.service';
import { KVComparator } from '../../shared/utils/utils';
import { DashboardFilterService } from '../../services/dashboard-filter.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-dashboard-filter',
  templateUrl: './dashboard-filter.component.html',
  styleUrls: ['./dashboard-filter.component.css']
})
export class DashboardFilterComponent implements OnInit {

  clients;
  countries;
  months;
  years;

  admin: boolean = false;
  client;
  country;
  month;
  year;

  /// Active filter rules
  f_array = [];
  filters = {};

  filterForm: FormGroup;

  constructor(
    private localStorage: LocalStoreManager,
    private _utils: KVComparator,
    private formBuilder: FormBuilder,
    private router: Router,
    public dashboardFilterService: DashboardFilterService,
    private dialogRef: MatDialogRef<DashboardFilterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.log(data);
    this.clients = data.clients;
    this.countries = data.countries;
    this.months = data.months;
    this.years = data.years;
  }

  ngOnInit() {
    this.isAdmin();
    this.activeFilterRules();
    this.filterForm = this.formBuilder.group({
      client: '',
      country: '',
      team: '',
      month: '',
      year: ''
    });
  }

  isAdmin(){
    if(this.router.url.includes('admin')) this.admin = true;
  }

  activeFilterRules() {
    if(this.admin) {
      this.client = this._utils.JsonTryParseReturnNumber(sessionStorage.getItem('client'));
      this.country = this._utils.JsonTryParseReturnNumber(sessionStorage.getItem('country'));
    } else {
      this.client = this._utils.JsonTryParseReturnNumber(sessionStorage.getItem('client_id'));
      this.country = this._utils.JsonTryParseReturnNumber(sessionStorage.getItem('country_id'));
    }
    this.month = this._utils.JsonTryParseReturnNumber(sessionStorage.getItem('month'));
    this.year = this._utils.JsonTryParseReturnNumber(sessionStorage.getItem('year'));
  }

  /// filter property by equality to rule
  filterExact(property: string, rule: any) {
    // console.log(property, rule);
    if(!rule) {
      delete this.filters[property];
      this[property] = null;
      this.localStorage.deleteData(property);
    } else {
      this.filters[property] = val => val.id == rule;
      sessionStorage.setItem(property, rule);
    }
    // console.log(this.filters);
    // this.applyFilters();
  }

  /// filter property by equality to rule
  filterYearMonth(property: string, rule: any) {
    // console.log(property, rule);
    if(!rule) {
      delete this.filters[property];
      this[property] = null;
      this.localStorage.deleteData(property);
    } else {
      this.filters[property] = val => val == rule;
      sessionStorage.setItem(property, rule);
    }
    // console.log(this.filters);
    // this.applyFilters();
  }

  /// filter  numbers greater than rule
  filterGreaterThan(property: string, rule: number) {
    this.filters[property] = val => val > rule;
    // this.applyFilters();
  }

  /// filter properties that resolve to true
  filterBoolean(property: string, rule: boolean) {
    if (!rule) this.removeFilter(property);
    else {
      this.filters[property] = val => val;
      // this.applyFilters();
    }
  }

  /// removes filter
  removeFilter(property: string) {
    delete this.filters[property];
    this[property] = null;
    // this.applyFilters();
  }

  applyFilters() {
    // console.log(this.filters);
    this.dialogRef.close(this.filters);
    let client = this.filterForm.value.client;
    let country = this.filterForm.value.country;
    let month = this.filterForm.value.month;
    let year = this.filterForm.value.year;

    this.filterArr(client, country, month, year);
    this.setFilter();
  }

  filterArr(client, country, month, year) {
    // set client
    if(this.admin) {
      this.f_array.push({"client": client});
      sessionStorage.setItem('client', client);
    } else {
      this.f_array.push({"client_id": client});
      sessionStorage.setItem('client_id', client);
    }
    
    // set country
    if(this.admin) {
      this.f_array.push({"country": country});
      sessionStorage.setItem('country', country);
    } else {
      this.f_array.push({"country_id": country});
      sessionStorage.setItem('country_id', country);
    }

    // set month
    this.f_array.push({"month": month});
    sessionStorage.setItem('month', month);
    
    // set year
    this.f_array.push({"year": year});
    sessionStorage.setItem('year', year);
  }

  setFilter() {
    this.f_array.forEach(item => {
      // console.log("LS Filters - ", this.filters);
      for(let key in item) {
        if(item.hasOwnProperty(key)) {
          // console.log(key, item[key])
          let property: string = key;
          let rule: number = item[key];
          if(!rule) {
            delete this.filters[property];
            this[property] = null;
            this.localStorage.deleteData(property);
          } else {
            if(property === "month" || property === "year") {
              (this.admin) ? this.filters[property] = rule : this.filters[property] = val => val == rule;
            } else {
              if(this.admin) {
                // this.filters[property] = val => val.id == rule;
                this.filters[property] = rule
              } else {
                this.filters[property] = val => val == rule;
              }
            }
          }
        }
      }
      console.log("Updated Filters -- ", this.filters);
    })
    
  }

  closeBtn(): void{
    this.dialogRef.close();
  }

}
