import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
// import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { Dashboard } from '../../../../shared/models/api.model';
import { DashboardService } from '../../../../services/dashboard.service';

import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-dashboard-detail',
  templateUrl: './dashboard-detail.component.html',
  styleUrls: ['./dashboard-detail.component.css']
})
export class DashboardDetailComponent implements OnInit, OnDestroy {
  // @Input()
  dashboard$: Observable<Dashboard>;
  // dashboard_url;
  // iframeURL: any = '';
  // trustedDashboardUrl: SafeResourceUrl;

  // url: string = "";
  // dashboardUrl: SafeResourceUrl;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dashboardService: DashboardService,
    // private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {

    const body = document.getElementsByTagName('body')[0];
    body.classList.add('dashboard','client');

    this.activatedRoute.paramMap.pipe(
      switchMap((params: ParamMap) =>
    this.dashboardService.getDashboard(params.get('slug')))
    ).subscribe(
      (res: Dashboard) => {
        this.dashboard$ = res['data'];
        // this.url = res['data']['embed_code'];
        // console.log(res['data']['embed_code']);
      }
    )
    // console.log(this.url)

    // this.dashboardUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);

  }

  // sendUrl(dashboard_url) {
  //   // this.iframeURL = playerUrl // try it first, if it doesn't work use the sanitized URL
  //   this.trustedDashboardUrl = this.sanitizer.bypassSecurityTrustResourceUrl(dashboard_url);
  //   this.iframeURL = this.trustedDashboardUrl;
  // }

  ngOnDestroy(): void {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('dashboard','client');
  }

}
