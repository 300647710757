import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ClientService } from '../../../../services/client.service';
// import { CountryService } from '../../../../services/country.service';
// import { TokenService } from '../../../../services/token.service';
// import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Observable } from 'rxjs';
import { Client } from '../../../../shared/models/api.model';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material';
import { EditClientComponent } from '../edit-client/edit-client.component';
import { ClientCountriesListComponent } from '../client-countries-list/client-countries-list.component';

@Component({
  selector: 'app-client-detail',
  templateUrl: './client-detail.component.html',
  styleUrls: ['./client-detail.component.css']
})
export class ClientDetailComponent implements OnInit {

  pageTitle = "Client Detail";
  client: number;
  client$: Observable<Client>;

  public error = [];

  constructor(
    private title: Title,
    private clientService: ClientService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<ClientDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any
  ) {
    this.client = data.slug;
    // console.log(data.slug);
  }

  ngOnInit() {
    this.title.setTitle(this.pageTitle);
    this.getClient();
  }

  getClient() {
    this.clientService.getClient(this.client).subscribe(
      (res: Client) =>{
        console.log(res);
        // this.clientForm.setValue(res['data']);
        this.client$ = res['data'];
      }
    )
  }

  editClient(client) {
    // console.log(client);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'menu-reveal';
    dialogConfig.data = { client: client };
    const editUser = this.dialog.open(EditClientComponent, dialogConfig);
    editUser.afterClosed().subscribe(
      () => {
        let slug = localStorage.getItem("curItem");
        if(slug) {
          this.clientService.getClient(slug).subscribe(
            (res) => {
              this.client$ = res['data'];
              // this.client = this.user$['client'][0]['name']
              // this.country = this.user$['country'][0]['name']
              // this.role = this.user$['roles'][0]['name']
              console.log("updated user",this.client$);
              localStorage.removeItem("curItem");
            }
          )
        }
      }
    )
  }

  editCountryList() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'menu-reveal';
    dialogConfig.data = { client: this.client$ };
    const userDashboards = this.dialog.open(ClientCountriesListComponent, dialogConfig);
    userDashboards.afterClosed().subscribe(
      (data) => {
        this.clientService.getClient(this.client$['slug']).subscribe(
          (res) => {
            this.client$ = res['data'];
            console.log("new user",this.client$);
          }
        )
      }
    )
  }

  handleResponse(data) {
    console.log(data);
    // this.router.navigateByUrl('/admin/clients');
  }

  handleError(error) {
    this.error = error.error.errors;
  }

  closeBtn(): void{
    this.dialogRef.close();
  }

}
