import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ComponentCanDeactivate } from '../shared/models/app.model';

@Injectable({
  providedIn: 'root'
})
export class CanDeactivateService implements CanDeactivate<ComponentCanDeactivate> {

  canDeactivate(component: ComponentCanDeactivate, route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if(component.canDeactivate()) {
      return true;
    } else {
      confirm('You have unsaved changes. Press Cancel to go back and save these changes, or OK to lose these changes.');
    }
    
  }

  constructor() { }
}
