import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, timer, of } from 'rxjs';
import { TokenService } from './token.service';
import { AuthService } from './auth.service';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AfterLoginService implements CanActivate {

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    return timer(500).pipe(
      switchMap(()=> {
        let validToken = this.token.loggedIn();

        if(validToken){
          // return validToken
          let roles = route.data["roles"] as Array<string>;
          if (roles) {
            var match = this.auth.roleMatch(roles);
            if (match) return of(true);
            else {
              this.router.navigate(['forbidden']);
              return of(false);
            }
          } else {
            return of(true);
          }
        } else {
          // console.log("TOKEN INVALID----------------------");
          this.router.navigate(['login']);
          return of(false);
        }
      })
    )
    // return this.token.loggedIn();
    
  }

  constructor(
    private token: TokenService,
    private router: Router,
    private auth: AuthService
  ) { }
}
