import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AdminSideNavService {

  hideSideNav: boolean = false;
 
  constructor() { }
 
  toggleSideNav(): void {
    // console.log("SideNav");
    this.hideSideNav = !this.hideSideNav;
  }
}
