import { Component, OnInit } from '@angular/core';
import { Team, Client } from 'src/app/shared/models/api.model';
import { ClientService } from 'src/app/services/client.service';
import { TeamService } from 'src/app/services/team.service';
// import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { Observable } from 'rxjs';
import { AddTeamComponent } from '../add-team/add-team.component';
import { TeamDetailComponent } from '../team-detail/team-detail.component';

@Component({
  selector: 'app-teams-list',
  templateUrl: './teams-list.component.html',
  styleUrls: ['./teams-list.component.css']
})
export class TeamsListComponent implements OnInit {

  clients: Observable<Client[]>;
  teams: Observable<Team[]>;  

  constructor(
    private clientService: ClientService,
    private teamService: TeamService,
    // private router: Router,
    private dialog: MatDialog
  ) { }

  clientDetail(team: Team) {
    console.log(team);
    // this.router.navigate(['articles', post.slug]);
  }

  ngOnInit() {
    this.getTeams();
  }

  getClients(): void {
    this.clientService.getClients().subscribe(
      (res: Client[]) => {
        // console.log(res);        
        this.clients = res['data'];
      }
    )
  }

  getTeams(): void {
    this.teamService.getTeams().subscribe(
      (res: Team[]) => {
        // console.log(res);        
        this.teams = res['data'];
      }
    )
  }

  addTeam() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'menu-reveal';
    const addTeam = this.dialog.open(AddTeamComponent, dialogConfig);
    addTeam.afterClosed().subscribe(
      () => {
        this.teamService.getTeams().subscribe(
          (res) => {
            this.teams = res['data'];
            console.log("updated teams",this.teams);
          }
        )
      }
    )
  }

  teamDetail(team) {
    // console.log(team);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'menu-reveal';
    dialogConfig.data = { team: team };
    const teamDetail = this.dialog.open(TeamDetailComponent, dialogConfig);
    teamDetail.afterClosed().subscribe(
      () => {
        this.teamService.getTeams().subscribe(
          (res) => {
            this.teams = res['data'];
            console.log("updated teams",this.teams);
          }
        )
      }
    )
  }

}
