import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray, FormGroupDirective, NgForm } from '@angular/forms';
import { TeamService } from '../../../../services/team.service';
import { ClientService } from '../../../../services/client.service';
import { CountryService } from '../../../../services/country.service';
import { RoleService } from '../../../../services/role.service';
import { UserService } from '../../../../services/user.service';
import { TokenService } from '../../../../services/token.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Client, Country, Role } from '../../../../shared/models/api.model';
import { SlugifyPipe } from '../../../../shared/pipes/slugify.pipe';
import { SlicePipe } from '@angular/common';
import { map, tap } from 'rxjs/operators';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material';
import { ErrorStateMatcher } from '@angular/material/core';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);

    return (invalidCtrl || invalidParent);
  }
}

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css']
})
export class AddUserComponent implements OnInit {

  pageTitle = "Add User";
  hide = true;

  clients$: Observable<Client[]>;
  countries$: Observable<Country[]>;
  roles$: Observable<Role[]>;

  userForm: FormGroup;
  matcher = new MyErrorStateMatcher();

  public error = [];

  constructor(
    private title: Title,
    private formBuilder: FormBuilder,
    private teamService: TeamService,
    private clientService: ClientService,
    private countryService: CountryService,
    private roleService: RoleService,
    private userService: UserService,
    private token : TokenService,
    private router : Router,
    private slugifyPipe : SlugifyPipe,
    private dialogRef : MatDialogRef<AddUserComponent>
  ) { }

  ngOnInit() {
    this.title.setTitle(this.pageTitle);

    this.getClients();
    this.getCountries();
    this.getRoles();

    

    this.userForm = this.formBuilder.group({
    // name: this.fullName(),
    firstname: ['', Validators.required],
    lastname: ['', Validators.required],
    name: '',
    email: ['', [Validators.required, Validators.email]],
    password: ['', 
      [
        Validators.required,
        Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&]).{8,}'),
      ]
    ],
    password_confirmation: ['', Validators.required,],
    client: '',
    country: '',
    role: ['', Validators.required]
    }, {validator: this.checkPasswords});
    
  }

  get f() { return this.userForm.controls; }

  get firstname() {
    return this.userForm.get('firstname');
  }

  // fullName(){
  //   // if(this.userForm.value.fistname != "" || this.userForm.value.lastname != ""){
  //     return `${this.userForm.get('firstname').value} ${this.userForm.get('lastname').value}`;
  //   // }
  // }
  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    let pass = group.controls.password.value;
    let confirmPass = group.controls.password_confirmation.value;

    return pass === confirmPass ? null : { notSame: true }
  }
  
  getClients(): void {
    this.clientService.getClients().subscribe(
      (res: Client[]) => {
        // console.log(res);
        this.clients$ = res['data'];
        // console.log(this.countries$);
      }
    )
  }

  getCountries(): void {
    this.countryService.getCountries().subscribe(
      (res: Country[]) => {
        // console.log(res);
        this.countries$ = res['data'];
        // console.log(this.countries$);
      }
    )
  }

  getRoles(): void {
    this.roleService.getRoles().subscribe(
      (res: Role[]) => {
        // console.log(res);
        this.roles$ = res['data'];
        // console.log(this.countries$);
      }
    )
  }

  onSubmit(){
    const formValue = this.userForm.value;
    console.log(formValue);
    // console.log(this.fullName());
    

    
    // console.log(this.form);
    this.userService.register(formValue).subscribe(
      data => this.handleResponse(data),
      error => this.handleError(error)
    )
  }

  // get firstname() {
  //   return this.userForm.get('firstname');
  // }
  // get lastname() {
  //   return this.userForm.get('lastname');
  // }
  // get email() {
  //   return this.userForm.get('email');
  // }
  // get password() {
  //   return this.userForm.get('password');
  // }
  // get password_confirmation() {
  //   return this.userForm.get('password_confirmation');
  // }


  handleResponse(data) {
    console.log(data);
    this.dialogRef.close();
    // this.router.navigateByUrl('/admin/users');
  }

  handleError(error) {
    this.error = error.error.errors;
  }

  slugify(input: string){
    return this.slugifyPipe.transform(input);
  }

  closeBtn() {
    this.dialogRef.close();
  }

}
