import { Injectable } from '@angular/core';
import { LoaderState } from '../shared/models/app.model';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private loaderSubject = new Subject<LoaderState>();
  loaderState = this.loaderSubject.asObservable();
  constructor() { }
  show() {
    // console.log("SHOW LOADER");
    this.loaderSubject.next(<LoaderState>{ show: true });
  }
  hide() {
    // console.log("HIDE LOADER");
    this.loaderSubject.next(<LoaderState>{ show: false });
  }
}
