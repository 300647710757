import { Component, OnInit } from '@angular/core';
import { AdminSideNavService } from '../../../services/admin-side-nav.service';

@Component({
  selector: 'app-admin-side-nav-toggler',
  templateUrl: './admin-side-nav-toggler.component.html',
  styleUrls: ['./admin-side-nav-toggler.component.css']
})
export class AdminSideNavTogglerComponent implements OnInit {

  constructor(
    public sideNavService: AdminSideNavService
  ) { }

  ngOnInit() {
  }

}
