import { Component, OnInit, NgZone } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { TokenService } from '../../services/token.service';
import { LocalStoreManager } from '../../services/local-store-manager.service';
import { AdminSideNavService } from 'src/app/services/admin-side-nav.service';

import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );

  public loggedIn: boolean;
  public isAdmin: boolean = false;
  // userID = this.localStorage.getData('id');
  // fn = this.localStorage.getData('fn');
  // ln = this.localStorage.getData('ln');

  constructor(
    private localStorage: LocalStoreManager,
    public auth: AuthService,
    private router: Router,
    private token: TokenService,
    private breakpointObserver: BreakpointObserver,
    public sideNavService: AdminSideNavService,
    // public zone: NgZone
  ) { }

  ngOnInit() {
    this.auth.authStatus.subscribe(
      (value) => {
        // console.log("NAV LoggedIN: ",value);
        this.loggedIn = value;
      }
    );

    this.userRole();

  }

  userRole() {
    const userRole = this.localStorage.getData('ur');
    if(userRole === "Admin" || userRole === "Super Admin"){
      this.isAdmin = true;
    } else {
      this.isAdmin = false;
    }
  }

  // logout(event: MouseEvent) {
  //   event.preventDefault();
  //   // this.token.destroy();
  //   this.localStorage.clearAllStorage()
  //   this.auth.changeAuthStatus(false);
  //   // localStorage.removeItem('c_user');
  //   this.router.navigateByUrl('/login');
  // }

  // goHome(event: MouseEvent) {
  //   event.preventDefault();
  //   this.router.navigateByUrl('/home');
  // }


}
