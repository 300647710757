import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { RoleService } from '../../../../services/role.service';
import { PermissionService } from '../../../../services/permission.service';
import { Permission } from '../../../../shared/models/api.model';
import { Router } from '@angular/router';
import { SlugifyPipe } from '../../../../shared/pipes/slugify.pipe';
import { SlicePipe } from '@angular/common';
import { map, tap } from 'rxjs/operators';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-add-role',
  templateUrl: './add-role.component.html',
  styleUrls: ['./add-role.component.css']
})
export class AddRoleComponent implements OnInit {

  permissions$: Observable<Permission[]>;
  roleForm: FormGroup;

  public error = [];

  constructor(
    private formBuilder: FormBuilder,
    private roleService: RoleService,
    private permService: PermissionService,
    private http: HttpClient,
    private router : Router,
    private slugifyPipe : SlugifyPipe,
    private mediaModal : MatDialog
  ) { }

  ngOnInit() {
    this.getPermissions();

    this.roleForm = this.formBuilder.group({
    name: ['', Validators.required],
    slug: '',
    permissions: ''
    });
    
  }

  getPermissions() {
    this.permService.getPermissions().subscribe (
      (res: Permission[]) => {
        this.permissions$ = res['data'];
      }
    )
  }

  onSubmit(){
    const formValue = this.roleForm.value;
    console.log(formValue);
    

    
    // console.log(this.form);
    this.roleService.createRole(formValue).subscribe(
      data => this.handleResponse(data),
      error => this.handleError(error)
    )
  }

  get name() {
    return this.roleForm.get('name');
  }

  handleResponse(data) {
    console.log(data);
    this.router.navigateByUrl('/admin/roles');
  }

  handleError(error) {
    this.error = error.error.errors;
  }

  slugify(input: string){
    return this.slugifyPipe.transform(input);
  }

}
