import { Component, OnInit, Inject, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Dashboard } from '../../../../shared/models/api.model';
import { DashboardService } from '../../../../services/dashboard.service';
import { Router } from '@angular/router';
import { Observable, fromEvent } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material';
import { AddDashboardUserComponent } from '../add-dashboard-user/add-dashboard-user.component';
import { map, debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';

@Component({
  selector: 'app-dashboard-users-list',
  templateUrl: './dashboard-users-list.component.html',
  styleUrls: ['./dashboard-users-list.component.css']
})
export class DashboardUsersListComponent implements OnInit {

  pageTitle = "Dashboard Users";
  hide: any = {};
  // dashboard$: Observable<Dashboard>;
  dashboard$: any = [];
  // dashboard: any = [];

  public error = [];

  constructor(
    private title: Title,
    private dashboardService: DashboardService,
    private router: Router,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<DashboardUsersListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // this.dashboard$ = data.dashboard
    this.dashboardData(data.dashboard);
  }

  @ViewChild('userSearch') input: ElementRef;

  ngOnInit() {
    this.title.setTitle(this.pageTitle);
    // this.getDashboards();
  }

  ngAfterViewInit() {
    fromEvent(this.input.nativeElement, 'keyup')
    .pipe(
      debounceTime(150),
      distinctUntilChanged(),
      tap(() => {
        this.filterUsers();
        // console.log("Filtered: ", this.fDashboards);
      })
    )
    .subscribe();

  }

  dashboardData(dashboard:any){
    let dashboardUsers = dashboard.users;
    for(let i = 0; i < dashboardUsers.length; i++){
      dashboardUsers[i].shown = true;
    }
    this.dashboard$ = dashboard;
    // console.log("TEST! ",user);
  }

  filterUsers() {
    var searchTerm = this.input.nativeElement.value;
    
    this.dashboard$.users.filter(
      (item) => {
        let m: boolean = item.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
        // console.log("BOOL: ", m);
        (m) ? item.shown = true : item.shown = false;
      }
    );

  }

  assignUser() {
    // console.log("clicked");
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'menu-reveal';
    dialogConfig.data = { dashboard: this.dashboard$ };
    const addUser = this.dialog.open(AddDashboardUserComponent, dialogConfig);
    addUser.afterClosed().subscribe(
      () => {
        this.dashboardService.getDashboard(this.dashboard$['slug']).subscribe(
          (res) => {
            // this.dashboard$ = res['data'];
            this.dashboardData(res['data']);
            console.log("new user",this.dashboard$);
          }
        )
      }
    )
  }

  removeDashboardUser(user) {
    // console.log(user);
    let data = {
      id: this.dashboard$['id'],
      user: user.id
    }
    // console.log(data);

    this.dashboardService.removeDashboardUser(data).subscribe(
      (data) => {
        this.handleResponse(data);
        if(data) {
          this.hide[user.id] = !this.hide[user.id]
        }
      },
      error => this.handleError(error)
    )
  }

  handleResponse(data) {
    console.log(data);
    // this.router.navigateByUrl('/admin/dashboards');
  }

  handleError(error) {
    this.error = error.error.errors;
  }

  closeBtn() {
    this.dialogRef.close();
  }

}
