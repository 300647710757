import { Component, OnInit, AfterViewInit, ViewChild, OnDestroy } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Team, Client, Country } from 'src/app/shared/models/api.model';
import { ClientService } from 'src/app/services/client.service';
import { TeamService } from 'src/app/services/team.service';
// import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { Observable } from 'rxjs';
import { AddTeamComponent } from '../add-team/add-team.component';
import { TeamDetailComponent } from '../team-detail/team-detail.component';
import { TeamClientCountryListComponent } from '../team-client-country-list/team-client-country-list.component';

@Component({
  selector: 'app-team-client-list',
  templateUrl: './team-client-list.component.html',
  styleUrls: ['./team-client-list.component.css']
})
export class TeamClientListComponent implements OnInit, OnDestroy {

  clients: Observable<Client[]>;
  teams: Observable<Team[]>;
  countries: Observable<Country[]>;

  displayedColumns: string[] = ['id', 'name', 'teams', 'users'];
  dataSource = new MatTableDataSource<any>();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private clientService: ClientService,
    private teamService: TeamService,
    // private router: Router,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.getClients();
    this.getTeams();
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  getClients(): void {
    this.clientService.getClients().subscribe(
      (res: Client[]) => {
        console.log(res);        
        this.clients = res['data'];
        this.dataSource.data = res['data'];
      }
    )
  }

  getTeams(): void {
    this.teamService.getTeams().subscribe(
      (res: Team[]) => {
        // console.log(res);        
        this.teams = res['data'];
      }
    )
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  addTeam() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'menu-reveal';
    const addTeam = this.dialog.open(AddTeamComponent, dialogConfig);
    addTeam.afterClosed().subscribe(
      () => {
        this.teamService.getTeams().subscribe(
          (res) => {
            this.teams = res['data'];
            console.log("updated teams",this.teams);
          }
        )
      }
    )
  }
  
  teamClientCountry(slug: string) {
    // console.log(client);
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('no-scroll');
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'menu-reveal';
    dialogConfig.data = { slug: slug };
    const clientDetail = this.dialog.open(TeamClientCountryListComponent, dialogConfig);
    clientDetail.afterClosed().subscribe(
      () => {
        const body = document.getElementsByTagName('body')[0];
        body.classList.remove('no-scroll');
        this.clientService.getClients().subscribe(
          (res) => {
            this.dataSource.data = res['data'];
            console.log("updated clients",this.dataSource);
          }
        )
      }
    )
  }

  teamDetail(team) {
    // console.log(team);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'menu-reveal';
    dialogConfig.data = { team: team };
    const teamDetail = this.dialog.open(TeamDetailComponent, dialogConfig);
    teamDetail.afterClosed().subscribe(
      () => {
        this.teamService.getTeams().subscribe(
          (res) => {
            this.teams = res['data'];
            console.log("updated teams",this.teams);
          }
        )
      }
    )
  }

  ngOnDestroy(): void {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('clients');
  }

}
