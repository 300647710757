import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { DashboardService } from '../../../../services/dashboard.service';
import { UserService } from '../../../../services/user.service';
import { YearMonthService } from '../../../../services/year-month.service';
// import { TokenService } from '../../../../services/token.service';
import { ClientService } from '../../../../services/client.service';
import { CountryService } from '../../../../services/country.service';
// import { Router } from '@angular/router';
import { SlugifyPipe } from '../../../../shared/pipes/slugify.pipe';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material';
import { Observable } from 'rxjs';
import { Dashboard, User } from 'src/app/shared/models/api.model';
// import { AssignUserComponent } from '../assign-user/assign-user.component';
// import { DashboardUsersListComponent } from '../dashboard-users-list/dashboard-users-list.component';

@Component({
  selector: 'app-edit-dashboard',
  templateUrl: './edit-dashboard.component.html',
  styleUrls: ['./edit-dashboard.component.css']
})
export class EditDashboardComponent implements OnInit {

  pageTitle = "Edit Dashboard";
  // dashboard$: Observable<Dashboard>;
  dashboard$: any = [];
  // users$: Observable<User>;
  clients: any = [];
  countries: any = [];

  monthss: any = [];
  yearss: any = [];
  dashboardForm: FormGroup;

  client;
  country;
  year;
  month;
  status: boolean;

  months: any = [
    {id:1, name:'January'},
    {id:2, name:'February'},
    {id:3, name:'March'},
    {id:4, name:'April'},
    {id:5, name:'May'},
    {id:6, name:'June'},
    {id:7, name:'July'},
    {id:8, name:'August'},
    {id:9, name:'September'},
    {id:10, name:'October'},
    {id:11, name:'November'},
    {id:12, name:'December'}
  ];

  years: any = [
    {id:2015, name:2015},
    {id:2016, name:2016},
    {id:2017, name:2017},
    {id:2018, name:2018},
    {id:2019, name:2019},
    {id:2020, name:2020},
    {id:2021, name:2021},
    {id:2022, name:2022},
    {id:2023, name:2023}
  ];

  public error = [];

  constructor(
    private formBuilder: FormBuilder,
    private dashboardService: DashboardService,
    private ymService: YearMonthService,
    // private token : TokenService,
    private clientService: ClientService,
    private countryService: CountryService,
    private userService: UserService,
    // private router : Router,
    private slugifyPipe : SlugifyPipe,
    private title: Title,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<EditDashboardComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // console.log("DASHBOARD DATA: ",data);
    this.dashboard$ = data.dashboard;
    this.client = data.dashboard.client.id;
    this.country = data.dashboard.country.id;
    this.year = data.dashboard.year;
    this.month = data.dashboard.month;
    (data.dashboard.status === "publish") ? this.status = true : this.status = false;
    // console.log(this.country);
  }

  ngOnInit() {
    this.title.setTitle(this.pageTitle);

    this.getClients();
    this.getCountries();
    // this.getMonths();
    // this.getUsers();

    this.dashboardForm = this.formBuilder.group({
      id: this.dashboard$['id'],
      name: ['', Validators.required],
      slug: '',
      embed_code: ['', Validators.required],
      year: ['', Validators.required],
      month: ['', Validators.required],
      client: '',
      country: '',
      status: ''
      });
      // this.dashboardForm.controls['client'].setValue(this.dashboard$['client'].id);
  }

  getClients() {
    this.clientService.getClients().subscribe(
      (res: any) => {
        this.clients = res['data'];
      }
    )
  }

  getCountries() {
    this.countryService.getCountries().subscribe(
      (res: any) => {
        this.countries = res['data'];
      }
    )
  }

  getMonths() {
    this.ymService.getMonths().subscribe(
      (res: any) => {
        // console.log(res);
      }
    )
  }

  onSelect(id: number) {
    this.countries = this.clients.find(client => client.id == id).countries;
  }

  // // onSelect(clientID: number) {
  // onSelect() {
  //   // console.log(clientID);
  //   // this.countries$ = this.clients$.find(c => c.name = client).countries;
  //   // this.dashboardForm.get('client').value.pipe(
  //   //   map((val: any[]) => val.reduce((pre, elem) => [...pre, elem.options], [])),
  //   //   map(val => val.flat())
  //   // );
  // }

  // getMonth(): void {
  //   this.ymService.getMonth().subscribe(
  //     // res => this.months = res
  //     res => console.log(res)
  //   )
  // }

  // getYear(): void {
  //   this.ymService.getYear().subscribe(
  //     res => this.years = res
  //   )
  // }

  onSubmit(){
    const formValue = this.dashboardForm.value;
    // console.log(formValue);
    localStorage.setItem("curItem", this.dashboardForm.value.slug)
    
    // console.log(this.dashboardForm);
    this.dashboardService.editDashboard(formValue).subscribe(
      data => this.handleResponse(data),
      error => this.handleError(error)
    )
  }

  get name() {
    return this.dashboardForm.get('name');
  }

  get embed_code() {
    return this.dashboardForm.get('embed_code');
  }

  handleResponse(data) {
    // console.log(data);
    this.dialogRef.close();
    // localStorage.setItem("curItem", data.slug)
    // this.router.navigateByUrl('/admin/dashboards');
  }

  handleError(error) {
    this.error = error.error.errors;
  }

  slugify(input: string){
    return this.slugifyPipe.transform(input);
  }

  closeBtn(): void{
    this.dialogRef.close();
  }

}
