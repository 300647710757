import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { ClientService } from '../../../../services/client.service';
// import { CountryService } from '../../../../services/country.service';
// import { TokenService } from '../../../../services/token.service';
// import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Observable } from 'rxjs';
import { Country, Client } from '../../../../shared/models/api.model';
import { SlugifyPipe } from '../../../../shared/pipes/slugify.pipe';
import { SlicePipe } from '@angular/common';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-edit-client',
  templateUrl: './edit-client.component.html',
  styleUrls: ['./edit-client.component.css']
})
export class EditClientComponent implements OnInit {

  pageTitle = "Edit Client";
  // countries$: Observable<Country[]>;
  clientForm: FormGroup;
  // client: Observable<Client>;
  // client$: Observable<Client>;
  client$: any = [];

  status: boolean;

  public error = [];

  constructor(
    private title: Title,
    private formBuilder: FormBuilder,
    private clientService: ClientService,
    // private countryService: CountryService,
    // private token : TokenService,
    // private router : Router,
    // private activatedRoute: ActivatedRoute,
    private slugifyPipe : SlugifyPipe,
    private dialogRef: MatDialogRef<EditClientComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any
  ) {
    this.client$ = data.client;
    console.log(data.client);
    (data.status === "publish") ? this.status = true : this.status = false;
  }

  ngOnInit() {

    this.title.setTitle(this.pageTitle);

    this.clientForm = this.formBuilder.group({
      id: this.client$['id'],
      name: ['', Validators.required],
      slug: '',
      // countries: ['', Validators.required],
      // teams: [],
      status: ''
    });

    // this.clientService.getClient(this.client).subscribe(
    //   (res: Client) =>{
    //     console.log(res);
    //     // this.clientForm.setValue(res['data']);
    //     this.client$ = res['data'];
    //   }
    // )
    console.log(this.clientForm);
    
  }

  onSubmit(){
    const formValue = this.clientForm.value;
    console.log(formValue);
    localStorage.setItem("curItem", this.clientForm.value.slug)
    
    // console.log(this.form);
    this.clientService.editClient(formValue).subscribe(
      data => this.handleResponse(data),
      error => this.handleError(error)
    )
  }

  get name() {
    return this.clientForm.get('name');
  }

  handleResponse(data) {
    console.log(data);
    this.dialogRef.close();
    // this.router.navigateByUrl('/admin/clients');
  }

  handleError(error) {
    this.error = error.error.errors;
  }

  // currentUser(){
  //   return localStorage.getItem('c_user');
  // }

  slugify(input: string){
    return this.slugifyPipe.transform(input);
  }

  closeBtn(): void{
    this.dialogRef.close();
  }

}
