import { CollectionViewer, DataSource } from '@angular/cdk/collections';
// import { Router } from '@angular/router';
import { User } from '../shared/models/api.model';
import { UserService } from './user.service';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';


export class UsersDataSource implements DataSource<User> {

  private usersSubject = new BehaviorSubject<User[]>([]);
  private usersCountSubject = new BehaviorSubject<number>(0);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  public usersCount$ = this.usersCountSubject.asObservable();

  constructor(private userService: UserService) {}

  loadUsers(filter:string, sortBy: string, sortDirection:string, pageIndex:number, pageSize:number) {
    this.loadingSubject.next(true);

    this.userService.loadUsers(filter, sortBy, sortDirection, pageIndex, pageSize).pipe(
      catchError(() => of([])),
      finalize(() => this.loadingSubject.next(false))
    )
    .subscribe(users => {
      // console.log(users);
      // console.log(users["meta"]["total"]);
      this.usersSubject.next(users["data"]);
      this.usersCountSubject.next(users["meta"]["total"])
    });

  }

  connect(collectionViewer: CollectionViewer): Observable<User[]> {
    // console.log("Connecting data source");
    return this.usersSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.usersSubject.complete();
    this.loadingSubject.complete();
  }

}
