import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { ClientService } from '../../../../services/client.service';
import { CountryService } from '../../../../services/country.service';
// import { TokenService } from '../../../../services/token.service';
// import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Observable } from 'rxjs';
import { Country, Client } from '../../../../shared/models/api.model';
import { SlugifyPipe } from '../../../../shared/pipes/slugify.pipe';
// import { SlicePipe } from '@angular/common';
// import { map, tap, switchMap } from 'rxjs/operators';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-create-client',
  templateUrl: './create-client.component.html',
  styleUrls: ['./create-client.component.css']
})
export class CreateClientComponent implements OnInit {

  pageTitle = "Add Client";

  countries$: Observable<Country[]>;
  clientForm: FormGroup;

  public error = [];

  constructor(
    private title: Title,
    private formBuilder: FormBuilder,
    private clientService: ClientService,
    private countryService: CountryService,
    // private token : TokenService,
    // private router : Router,
    // private activatedRoute: ActivatedRoute,
    private slugifyPipe : SlugifyPipe,
    private dialogRef: MatDialogRef<CreateClientComponent>,
    // @Inject(MAT_DIALOG_DATA) public data:any
  ) { }

  ngOnInit() {
    this.title.setTitle(this.pageTitle);

    this.getCountries();

    this.clientForm = this.formBuilder.group({
      name: ['', Validators.required],
      slug: '',
      countries: ['', Validators.required],
      status: ''
    });
  }

  getCountries(): void {
    this.countryService.getCountries().subscribe(
      (res: Country[]) => {
        // console.log(res);
        this.countries$ = res['data'];
        // console.log(this.countries$);
      }
    )
  }

  onSubmit(){
    const formValue = this.clientForm.value;
    console.log(formValue);
    

    
    // console.log(this.form);
    this.clientService.createClient(formValue).subscribe(
      data => this.handleResponse(data),
      error => this.handleError(error)
    )
  }

  get name() {
    return this.clientForm.get('name');
  }

  handleResponse(data) {
    console.log(data);
    this.dialogRef.close();
    // this.router.navigateByUrl('/admin/clients');
  }

  handleError(error) {
    this.error = error.error.errors;
  }

  currentUser(){
    return localStorage.getItem('c_user');
  }

  slugify(input: string){
    return this.slugifyPipe.transform(input);
  }

  closeBtn() {
    this.dialogRef.close();
  }

}
