import { Component, OnInit, AfterViewInit, ViewChild, OnDestroy, ElementRef } from '@angular/core';
import { UserService } from '../../../../services/user.service';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { Router } from '@angular/router';
import { merge, fromEvent } from 'rxjs';
import { AddUserComponent } from '../add-user/add-user.component';
import { UserDetailComponent } from '../user-detail/user-detail.component';
import { tap, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { UsersDataSource } from '../../../../services/users.datasource';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.css']
})
export class UsersListComponent implements AfterViewInit, OnInit, OnDestroy {

  // users$: Observable<User[]>;

  dataSource: UsersDataSource;
  displayedColumns: string[] = ['id', 'name', 'email', 'client', 'roles'];

  // dataSource = new MatTableDataSource<any>();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('input') input: ElementRef;

  constructor(
    private userService: UserService,
    private router: Router,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.dataSource = new UsersDataSource(this.userService);
    this.dataSource.loadUsers('', 'id', 'asc', 0, 20);

    const body = document.getElementsByTagName('body')[0];
    body.classList.add('users');

    // this.userService.testUsers('', 'asc', 0, 3).subscribe(
    //   (users) => {
    //     console.log(users);
    //   }
    // )
  }
  
  ngAfterViewInit() {
    // server-side search
    fromEvent(this.input.nativeElement,'keyup')
    .pipe(
      debounceTime(150),
      distinctUntilChanged(),
      tap(() => {
        this.paginator.pageIndex = 0;
        this.loadUsersPage();
      })
    )
    .subscribe();
    
    this.dataSource.usersCount$
      .pipe(
        tap((count) => {
          this.paginator.length = count;
        })
      ).subscribe();

    // reset the paginator after sorting
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.loadUsersPage())
      )
      .subscribe();
  }

  loadUsersPage() {
    this.dataSource.loadUsers(
      this.input.nativeElement.value,
      '',
      this.sort.direction,
      this.paginator.pageIndex,
      this.paginator.pageSize);
  }

  addUser() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('no-scroll');
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'menu-reveal';
    const addUser = this.dialog.open(AddUserComponent, dialogConfig);
    addUser.afterClosed().subscribe(
      () => {
        const body = document.getElementsByTagName('body')[0];
        body.classList.remove('no-scroll');
        this.loadUsersPage();
      }
    )
  }

  userDetail(userId: number) {
    // console.log(userId);
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('no-scroll');
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'menu-reveal';
    dialogConfig.data = { userId: userId };
    const userDetail = this.dialog.open(UserDetailComponent, dialogConfig);
    userDetail.afterClosed().subscribe(
      () => {
        const body = document.getElementsByTagName('body')[0];
        body.classList.remove('no-scroll');
        this.loadUsersPage();
      }
    )
  }

  ngOnDestroy(): void {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('users');
  }

}
