import { Component, OnInit, Inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { DashboardService } from '../../../../services/dashboard.service';
// import { TokenService } from '../../../../services/token.service';
import { UserService } from 'src/app/services/user.service';
// import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { User, Dashboard} from '../../../../shared/models/api.model';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material';
// import { SlicePipe } from '@angular/common';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-assign-user',
  templateUrl: './assign-user.component.html',
  styleUrls: ['./assign-user.component.css']
})
export class AssignUserComponent implements OnInit {

  pageTitle = "Add Dashboard Users"
  users: any = [];
  dashboard: any = [];
  assignUserForm: FormGroup;

  public error = [];

  constructor(
    private formBuilder: FormBuilder,
    private dashboardService: DashboardService,
    // private token : TokenService,
    private userService: UserService,
    // private router : Router,
    private title: Title,
    private dialogRef: MatDialogRef<AssignUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.log(data);
    this.dashboard = data.dashboard;
  }

  ngOnInit() {
    this.title.setTitle(this.pageTitle);
    
    this.getUsers();
    this.assignUserForm = this.formBuilder.group({
      id: this.dashboard.id,
      users: this.formBuilder.group([])
      });
  }

  getUsers() {
    this.userService.getUsers().subscribe(
      (res: any) => {
        this.users = res['data'];
        console.log(this.users);
        this.users.forEach((user: any) => {
          // console.log(user);
            const users = <FormGroup>this.assignUserForm.get('users');
            users.addControl(user.id, new FormControl(false));
            // console.log(users);
          });
      }
    )
  }

  onSubmit(){
    console.log(this.assignUserForm.value);
    
    var usersObj = this.assignUserForm.value.users;
    var selectedUserIds = [];
    for (var userId in usersObj) {
        if (usersObj.hasOwnProperty(userId)) {
          if(usersObj[userId])//If selected
          {
              selectedUserIds.push(userId);
          }

        }
    }
    console.log(selectedUserIds);

    let data = {
      id: this.assignUserForm.value.id,
      users: selectedUserIds
    }

    console.log(data);

    // const formValue = this.assignUserForm.value;
    // console.log(formValue);
    
    // console.log(this.form);
    // this.dashboardService.addDashboardUser(formValue).subscribe(
    //   data => this.handleResponse(data),
    //   error => this.handleError(error)
    // )
  }

  handleResponse(data) {
    console.log(data);
    // this.router.navigateByUrl('/admin/dashboards');
  }

  handleError(error) {
    this.error = error.error.errors;
  }

  currentUser(){
    return localStorage.getItem('id');
  }

}
