import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { TeamService } from '../../../../services/team.service';
import { ClientService } from '../../../../services/client.service';
import { CountryService } from '../../../../services/country.service';
import { TokenService } from '../../../../services/token.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Client, Country } from '../../../../shared/models/api.model';
import { SlugifyPipe } from '../../../../shared/pipes/slugify.pipe';
import { SlicePipe } from '@angular/common';
import { map, tap } from 'rxjs/operators';
import { MatDialog, MatDialogConfig } from '@angular/material';

@Component({
  selector: 'app-add-team',
  templateUrl: './add-team.component.html',
  styleUrls: ['./add-team.component.css']
})
export class AddTeamComponent implements OnInit {

  clients$: Observable<Client[]>;
  countries$: Observable<Country[]>;
  teamForm: FormGroup;

  public error = [];

  constructor(
    private formBuilder: FormBuilder,
    private teamService: TeamService,
    private clientService: ClientService,
    private countryService: CountryService,
    private token : TokenService,
    private router : Router,
    private slugifyPipe : SlugifyPipe,
    private mediaModal : MatDialog
  ) { }

  ngOnInit() {
    this.getClients();
    this.getCountries();

    this.teamForm = this.formBuilder.group({
    name: ['', Validators.required],
    slug: '',
    client: ['', Validators.required],
    countries: ['', Validators.required]
    });
    
  }

  getClients(): void {
    this.clientService.getClients().subscribe(
      (res: Client[]) => {
        // console.log(res);
        this.clients$ = res['data'];
        // console.log(this.countries$);
      }
    )
  }

  getCountries(): void {
    this.countryService.getCountries().subscribe(
      (res: Country[]) => {
        // console.log(res);
        this.countries$ = res['data'];
        // console.log(this.countries$);
      }
    )
  }

  onSubmit(){
    const formValue = this.teamForm.value;
    console.log(formValue);
    

    
    // console.log(this.form);
    this.teamService.createTeam(formValue).subscribe(
      data => this.handleResponse(data),
      error => this.handleError(error)
    )
  }

  get name() {
    return this.teamForm.get('name');
  }

  handleResponse(data) {
    console.log(data);
    this.router.navigateByUrl('/admin/teams');
  }

  handleError(error) {
    this.error = error.error.errors;
  }

  slugify(input: string){
    return this.slugifyPipe.transform(input);
  }

}
