import { Component, OnInit } from '@angular/core';
import { Permission } from 'src/app/shared/models/api.model';
import { PermissionService } from 'src/app/services/permission.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-permissions-list',
  templateUrl: './permissions-list.component.html',
  styleUrls: ['./permissions-list.component.css']
})
export class PermissionsListComponent implements OnInit {

  permissions: Observable<Permission[]>;  

  constructor(
    private permService: PermissionService,
    private router: Router
  ) { }

  permDetail(permission: Permission) {
    console.log(permission);
    // this.router.navigate(['articles', post.slug]);
  }

  ngOnInit() {
    this.getPermissions();
  }

  getPermissions(): void {
    this.permService.getPermissions().subscribe(
      (res: Permission[]) => {
        // console.log(res);        
        this.permissions = res['data'];
      }
    )
  }

}
