import { Component, OnInit } from '@angular/core';
import { Role } from 'src/app/shared/models/api.model';
import { RoleService } from 'src/app/services/role.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-roles-list',
  templateUrl: './roles-list.component.html',
  styleUrls: ['./roles-list.component.css']
})
export class RolesListComponent implements OnInit {

  roles: Observable<Role[]>;  

  constructor(
    private roleService: RoleService,
    private router: Router
  ) { }

  roleDetail(role: Role) {
    console.log(role);
    // this.router.navigate(['articles', post.slug]);
  }

  ngOnInit() {
    this.getRoles();
  }

  getRoles(): void {
    this.roleService.getRoles().subscribe(
      (res: Role[]) => {
        // console.log(res);        
        this.roles = res['data'];
      }
    )
  }

}
