import { Injectable } from '@angular/core';
import { LocalStoreManager } from './local-store-manager.service';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  // PROD
  private iss = {
    login : 'https://api.tomorrowtth.com/api/login',
    register : 'https://api.tomorrowtth.com//api/register',
    refresh : 'https://api.tomorrowtth.com//api/refresh'
  }

  // DEV
  // private iss = {
  //   login : 'http://localhost:8000/api/login',
  //   register : 'http://localhost:8000/api/register',
  //   refresh : 'http://localhost:8000/api/refresh'
  // }

  constructor(private localStorage: LocalStoreManager) { }

  handle(token, user, role) {
    this.setToken(token);
    this.setUser(user, role);
  }

  setToken(token) {
    // localStorage.setItem('token', token);
    this.localStorage.saveSyncedSessionData('accessToken', token);
    // this.localStorage.savePermanentData(token, "accessToken");
  }

  setUser(user, role) {
    // console.log(user, role);
    // localStorage.setItem('currentUser', JSON.stringify(user));
    
    // localStorage.setItem('id', user['id']);
    // localStorage.setItem('fn', user['firstname']);
    // localStorage.setItem('ln', user['lastname']);
    // localStorage.setItem('ur', role);

    this.localStorage.saveSyncedSessionData('id', user['id']);
    this.localStorage.saveSyncedSessionData('fn', user['firstname']);
    this.localStorage.saveSyncedSessionData('ln', user['lastname']);
    this.localStorage.saveSyncedSessionData('ur', role);

    // const exp_time = Date.now() + 120;
    // localStorage.setItem('timer', JSON.stringify(exp_time));
  }

  getToken() {
    // return localStorage.getItem('token');
    return this.localStorage.getData('accessToken');
    // return sessionStorage.getItem('accessToken');
  }

  // getUser() {
  //   return localStorage.getItem('currentUser');
  // }

  destroy() {
    localStorage.removeItem('token');
    // localStorage.removeItem('currentUser');
    localStorage.removeItem('id');
    localStorage.removeItem('fn');
    localStorage.removeItem('ln');
    localStorage.removeItem('ur');
  }
  // removeToken() {
  //   localStorage.removeItem('token');
  // }

  isValid() {
    const token = this.getToken();
    // console.log("TOKEN", token);
    
    if(token) {
      const payload = this.payload(token);
      if(payload){
        return Object.values(this.iss).indexOf(payload.iss) > -1 ? true : false;
      }
    }
    return false;
  }

  payload(token) {
    const payload = token.split('.')[1];
    
    return this.decode(payload);
  }

  decode(payload) {
    return JSON.parse(window.atob(payload));
  }

  loggedIn() {
    return this.isValid();
  }
}
