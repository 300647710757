import { Component, OnInit, Inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray, FormGroupDirective, NgForm } from '@angular/forms';
import { TeamService } from '../../../../services/team.service';
import { ClientService } from '../../../../services/client.service';
import { CountryService } from '../../../../services/country.service';
import { RoleService } from '../../../../services/role.service';
import { UserService } from '../../../../services/user.service';
import { TokenService } from '../../../../services/token.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Client, Country, Role } from '../../../../shared/models/api.model';
import { SlugifyPipe } from '../../../../shared/pipes/slugify.pipe';
import { SlicePipe } from '@angular/common';
import { map, tap } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material';
import { ErrorStateMatcher } from '@angular/material/core';
import { User } from 'src/app/shared/models/user.model';
import { SlideInOutAnimation, SlideUpDown } from '../../../../shared/animations/animations';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css'],
  animations: [SlideInOutAnimation, SlideUpDown]
})
export class EditUserComponent implements OnInit {

  pageTitle = "Edit User";
  animationState = 'out';
  // animationState = 'up';
  hide = true;
  passwordLink: string;

  // user$: Observable<User>;
  user$: any = [];
  clients$: Observable<Client[]>;
  countries$: Observable<Country[]>;
  roles$: Observable<Role[]>;

  client;
  country;
  role;

  userForm: FormGroup;
  // matcher = new MyErrorStateMatcher();

  public error = [];

  constructor(
    private formBuilder: FormBuilder,
    private teamService: TeamService,
    private clientService: ClientService,
    private countryService: CountryService,
    private roleService: RoleService,
    private userService: UserService,
    private token : TokenService,
    private router : Router,
    private slugifyPipe : SlugifyPipe,
    private title: Title,
    private mediaModal : MatDialog,
    private dialogRef: MatDialogRef<EditUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.log(data);
    this.user$ = data.user;
    this.client = data.user.client[0]['id'];
    this.country = data.user.country[0]['id'];
    this.role = data.user.roles[0]['id'];
    console.log(data.user.client[0]['id']);
  }

  ngOnInit() {
    this.title.setTitle(this.pageTitle);

    this.getClients();
    this.getCountries();
    this.getRoles();

    this.userForm = this.formBuilder.group({
    id: this.user$['id'],
    firstname: ['', Validators.required],
    lastname: ['', Validators.required],
    name: '',
    email: ['', [Validators.required, Validators.email]],
    password: '',
    // password_confirmation: ['', Validators.required],
    client: '',
    country: '',
    role: ['', Validators.required]
    });
    
  }

  get f() { return this.userForm.controls; }

  // get firstname() {
  //   return this.userForm.get('firstname');
  // }

  // checkPasswords(group: FormGroup) { // here we have the 'passwords' group
  //   let pass = group.controls.password.value;
  //   let confirmPass = group.controls.password_confirmation.value;

  //   return pass === confirmPass ? null : { notSame: true }
  // }
  
  getClients(): void {
    this.clientService.getClients().subscribe(
      (res: Client[]) => {
        // console.log(res);
        this.clients$ = res['data'];
        // console.log(this.countries$);
      }
    )
  }

  getCountries(): void {
    this.countryService.getCountries().subscribe(
      (res: Country[]) => {
        // console.log(res);
        this.countries$ = res['data'];
        // console.log(this.countries$);
      }
    )
  }

  getRoles(): void {
    this.roleService.getRoles().subscribe(
      (res: Role[]) => {
        // console.log(res);
        this.roles$ = res['data'];
        // console.log(this.countries$);
      }
    )
  }

  onSubmit(){
    const formValue = this.userForm.value;
    console.log(formValue);

    
    // console.log(this.form);
    this.userService.editUser(formValue).subscribe(
      data => this.handleResponse(data),
      error => this.handleError(error)
    )
  }
  handleResponse(data) {
    console.log(data);
    this.dialogRef.close();
    // this.router.navigateByUrl('/admin/users');
  }

  handleError(error) {
    this.error = error.error.errors;
  }

  slugify(input: string){
    return this.slugifyPipe.transform(input);
  }

  toggleDiv(divName: string) {
    if (divName === 'resetPassword') {
      console.log(this.animationState);
      this.animationState = this.animationState === 'out' ? 'in' : 'out';
      console.log(this.animationState);
    }
    if (divName === 'passwordLink') {
      console.log(this.animationState);
      this.animationState = this.animationState === 'out' ? 'in' : 'out';
      console.log(this.animationState);
    }
  }

  getPasswordLink() {
    let passwordRequest = {
      email: this.user$.email,
      notify: 'no'
    };
    console.log(passwordRequest);
    this.userService.generatePasswordLink(passwordRequest).subscribe(
      (res) => {
        console.log("PASSWORD LINK: ", res);
        this.passwordLink = res['reset_url'];
      }
    )
  }

  closeBtn(): void{
    this.dialogRef.close();
  }

}
