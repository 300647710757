import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Role } from '../shared/models/api.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  constructor(
    private http: HttpClient
  ) { }

  // Add Permission
  createRole(data){
    return this.http.post(`/role`, data);
  }

  // Get Permissions
  getRoles(): Observable<Role[]>{
    return this.http.get<Role[]>(`/roles`);
  }

  // Get Permission
  getRole(slug): Observable<Role> {
    return this.http.get<Role>(`/role/`+slug);
    // .pipe(
    //   tap(_ => console.log(`fetched post id=${id}`))
    //   // catchError(this.handleError<Post>(`getPost id=${id}`))
    // );
  }
}
