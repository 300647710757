import { Injectable } from '@angular/core';
import { Permission } from '../shared/models/api.model';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  constructor(
    private http: HttpClient
  ) { }

  // Add Permission
  createPermission(data){
    return this.http.post(`/permission`, data);
  }

  // Get Permissions
  getPermissions(): Observable<Permission[]>{
    return this.http.get<Permission[]>(`/permissions`);
  }

  // Get Permission
  getPermission(slug): Observable<Permission> {
    return this.http.get<Permission>(`/permission/`+slug);
    // .pipe(
    //   tap(_ => console.log(`fetched post id=${id}`))
    //   // catchError(this.handleError<Post>(`getPost id=${id}`))
    // );
  }
}
