import { NgModule, Component } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
// import { RegisterComponent } from './components/register/register.component';
// import { ProfileComponent } from './components/profile/profile.component';
import { RequestResetComponent } from './components/password/request-reset/request-reset.component';
import { ResponseResetComponent } from './components/password/response-reset/response-reset.component';
import { BeforeLoginService } from './services/before-login.service';
import { AfterLoginService } from './services/after-login.service';
// import { CreatePostComponent } from './components/admin/create-post/create-post.component';
// import { PostsComponent } from './components/posts/posts.component';
// import { PostDetailComponent } from './components/post-detail/post-detail.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
// import { CategoryComponent } from './components/admin/category/category.component';
// import { MediaComponent } from './components/admin/media/media.component';
import { UsersListComponent } from './components/admin/users/users-list/users-list.component';
import { AddUserComponent } from './components/admin/users/add-user/add-user.component';
import { CreateDashboardComponent } from './components/admin/dashboards/create-dashboard/create-dashboard.component';
import { DashboardsListComponent } from './components/admin/dashboards/dashboards-list/dashboards-list.component';
import { ClientsListComponent } from './components/admin/clients/clients-list/clients-list.component';
import { CreateClientComponent } from './components/admin/clients/create-client/create-client.component';
import { TeamsListComponent } from './components/admin/teams/teams-list/teams-list.component';
import { AddTeamComponent } from './components/admin/teams/add-team/add-team.component';
import { PermissionsListComponent } from './components/admin/permissions/permissions-list/permissions-list.component';
import { AddPermissionComponent } from './components/admin/permissions/add-permission/add-permission.component';
import { RolesListComponent } from './components/admin/roles/roles-list/roles-list.component';
import { AddRoleComponent } from './components/admin/roles/add-role/add-role.component';
import { AdminComponent } from './components/admin/admin.component';
import { ForbiddenComponent } from './components/forbidden/forbidden.component';
import { DashboardsComponent } from './components/public/dashboards/dashboards.component';
import { DashboardDetailComponent } from './components/public/dashboards/dashboard-detail/dashboard-detail.component';
import { TeamClientListComponent } from './components/admin/teams/team-client-list/team-client-list.component';
import { CanDeactivateService } from './services/can-deactivate.service';

const appRoutes: Routes = [
  // { path: '', component: AppComponent, canActivate: [BeforeLoginService, AfterLoginService] },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'login', component: LoginComponent, canActivate: [BeforeLoginService] },
  // { path: 'register', component: RegisterComponent, canActivate: [BeforeLoginService] },
  // { path: 'profile', component: ProfileComponent, canActivate: [AfterLoginService] },
  { path: 'request-password-reset', component: RequestResetComponent, canActivate: [BeforeLoginService] },
  { path: 'response-password-reset', component: ResponseResetComponent, canActivate: [BeforeLoginService] },
  { path: 'create-password', component: ResponseResetComponent, canActivate: [BeforeLoginService] },

  // Media
  // { path: 'media', component: MediaComponent, canActivate: [AfterLoginService] },

  //Posts
  // { path: 'create-post', component: CreatePostComponent, canActivate: [AfterLoginService] },
  // { path: 'articles', component: PostsComponent, canActivate: [BeforeLoginService, AfterLoginService] },
  // { path: 'articles/:slug', component: PostDetailComponent, canActivate: [BeforeLoginService, AfterLoginService] },

  { path: 'forbidden', component: ForbiddenComponent, canActivate: [BeforeLoginService, AfterLoginService] },

  // Dashboards
  // {path: 'dashboards', loadChildren: './components/public/public.module#PublicModule', canActivate: [AfterLoginService], data: { roles: ['Basic','Admin'] } },
  {path: 'dashboards', component: DashboardsComponent, canActivate: [AfterLoginService], data: { roles: ['Basic', 'Admin', 'Super Admin'] } },
  {path: 'dashboards/:slug', component: DashboardDetailComponent, canActivate: [AfterLoginService], data: { roles: ['Basic','Admin', 'Super Admin'] } },

  // ADMIN
  // {path: 'admin', loadChildren: './components/admin/admin.module#AdminModule', canActivate: [AfterLoginService], data: { roles: ['Super Admin', 'Admin'] } },
  {path: 'admin', component: AdminComponent, canActivate: [AfterLoginService], data: { roles: ['Admin', 'Super Admin'] } },

  // { path: 'admin/create-post', component: CreatePostComponent, canActivate: [AfterLoginService], data: { roles: ['Admin'] } },
  // { path: 'admin/add-category', component: CategoryComponent, canActivate: [AfterLoginService], data: { roles: ['Admin'] } },

  // Users
  { path: 'admin/users', component: UsersListComponent, canActivate: [AfterLoginService], data: { roles: ['Admin', 'Super Admin'] } },
  { path: 'admin/add-user', component: AddUserComponent, canActivate: [AfterLoginService], data: { roles: ['Admin', 'Super Admin'] } },

  // Dashboard
  { path: 'admin/dashboards', component: DashboardsListComponent, canActivate: [AfterLoginService], data: { roles: ['Admin', 'Super Admin'] } },
  { path: 'admin/add-dashboard', component: CreateDashboardComponent, canActivate: [AfterLoginService], canDeactivate: [CanDeactivateService], data: { roles: ['Admin', 'Super Admin'] } },

  // Clients
  { path: 'admin/clients', component: ClientsListComponent, canActivate: [AfterLoginService], data: { roles: ['Admin', 'Super Admin'] } },
  { path: 'admin/add-client', component: CreateClientComponent, canActivate: [AfterLoginService], data: { roles: ['Admin', 'Super Admin'] } },

  // Teams
  { path: 'admin/teams', component: TeamClientListComponent, canActivate: [AfterLoginService], data: { roles: ['Admin', 'Super Admin'] } },
  { path: 'admin/add-team', component: AddTeamComponent, canActivate: [AfterLoginService], data: { roles: ['Admin', 'Super Admin'] } },

  // Permissions
  { path: 'admin/permissions', component: PermissionsListComponent, canActivate: [AfterLoginService], data: { roles: ['Admin', 'Super Admin'] } },
  { path: 'admin/add-permission', component: AddPermissionComponent, canActivate: [AfterLoginService], data: { roles: ['Admin', 'Super Admin'] } },

  // Roles
  { path: 'admin/roles', component: RolesListComponent, canActivate: [AfterLoginService], data: { roles: ['Admin', 'Super Admin'] } },
  { path: 'admin/add-role', component: AddRoleComponent, canActivate: [AfterLoginService], data: { roles: ['Admin', 'Super Admin'] } },

  //404
  { path: '**', component: PageNotFoundComponent, canActivate: [BeforeLoginService, AfterLoginService] }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes)
  ],
  exports: [RouterModule],
  declarations: []
})
export class AppRoutingModule { }
