import { Injectable } from "@angular/core";
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from "@angular/common/http";
import { AuthService } from '../services/auth.service';
import { LocalStoreManager } from "../services/local-store-manager.service";
import { throwError, Observable, BehaviorSubject, of, timer } from "rxjs";
import { catchError, filter, take, switchMap, finalize } from "rxjs/operators";
import { Router } from "@angular/router";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private AUTH_HEADER = "Authorization";
  // private token = localStorage.getItem('token');
  private apiUrl = 'https://api.tomorrowtth.com/api'; //PROD
  // private apiUrl = 'http://localhost:8000/api'; //DEV
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private auth : AuthService, private localStorage: LocalStoreManager, private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // return timer(500).pipe(         // <== Wait 2 Seconds
    //   switchMap( ()=> {

        if (!req.headers.has('Content-Type')) {
          req = req.clone({
            headers: req.headers.set('Content-Type', 'application/json')
          });
        }
    
        // console.log(this.apiUrl + req.url);
        const url = this.apiUrl + req.url;
    
        const q = {url: url, urlWithParams: url};
        req = Object.assign(req, q);
    
    
        req = this.addAuthenticationToken(req);
        // console.log("updated",req);

        return <any>next.handle(req).pipe(
          catchError((error: HttpErrorResponse) => {
            if (error && error.status === 401) {
              // if (timer && (Date.now() > timer)) {
          // console.log("TOKEN EXPIRED");

          // We don't want to refresh token for some requests like login or refresh token itself
          // So we verify url and we throw an error if it's the case
          if ( req.url.includes("refresh") || req.url.includes("login") ) {
            // We do another check to see if refresh token failed
            // In this case we want to logout user and to redirect it to login page

            // if (req.url.includes("refresh")) {
              this.auth.logout();
              // this.localStorage.clearAllStorage();
              // this.router.navigate(['login']);
            // }

            return <any>throwError(error);
          }

          // if ( req.url.includes("logout") ) {
          //   this.router.navigate()
          // }


          // 401 errors are most likely going to be because we have an expired token that we need to refresh.
          if (this.refreshTokenInProgress) {
            // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
            // which means the new token is ready and we can retry the request again
            return this.refreshTokenSubject.pipe(
              filter(result => result !== null),
              take(1),
              switchMap(() => next.handle(<any> this.addAuthenticationToken(req)))
            );
          } else {
            // console.log("REFRESH REQ:",req);
            this.refreshTokenInProgress = true;

            // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
            this.refreshTokenSubject.next(null);
            
            // return this.refreshAccessToken().pipe(
            //   switchMap((success: boolean) => {
            return this.auth.refreshAccessToken().pipe(
              switchMap((r_token) => {
                // console.log("REFRESH INTERCEPTOR CALL", r_token);
                this.refreshTokenSubject.next(r_token['token']);
                return next.handle(<any> this.addAuthenticationToken(req));
              }),
              // When the call to refreshToken completes we reset the refreshTokenInProgress to false
              // for the next time the token needs to be refreshed
              finalize(() => this.refreshTokenInProgress = false)
            );
          }
            } else {
              return <any>throwError(error);
            }
          })
      //   );
      // })   // <== Switch to the Http Stream
    )

    // if (!req.headers.has('Content-Type')) {
    //   req = req.clone({
    //     headers: req.headers.set('Content-Type', 'application/json')
    //   });
    // }

    // console.log(this.apiUrl + req.url);
    // const url = this.apiUrl + req.url;

    // const q = {url: url, urlWithParams: url};
    // req = Object.assign(req, q);


    // req = this.addAuthenticationToken(req);
    // console.log("updated",req);

    // // const timer = JSON.parse(localStorage.getItem('timer'));
    // // // console.log(Date.now(), timer);
    // // if (timer && (Date.now() > timer)) {
    // //   console.log("TOKEN_EXPIRED");
    // // }

    // return <any> next.handle(req).pipe(
    //   catchError((error: HttpErrorResponse) => {
    //     if (error && error.status === 401) {
    //     // if (timer && (Date.now() > timer)) {
    //       // console.log("TOKEN EXPIRED");

    //       // We don't want to refresh token for some requests like login or refresh token itself
    //         // So we verify url and we throw an error if it's the case
    //         if ( req.url.includes("refresh") || req.url.includes("login") ) {
    //           // We do another check to see if refresh token failed
    //           // In this case we want to logout user and to redirect it to login page

    //           // if (req.url.includes("refresh")) {
    //           //     this.auth.logout();
    //           // }

    //           return <any>throwError(error);
    //       }


    //       // 401 errors are most likely going to be because we have an expired token that we need to refresh.
    //       if (this.refreshTokenInProgress) {
    //         // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
    //         // which means the new token is ready and we can retry the request again
    //         return this.refreshTokenSubject.pipe(
    //           filter(result => result !== null),
    //           take(1),
    //           switchMap(() => next.handle(<any> this.addAuthenticationToken(req)))
    //         );
    //       } else {
    //         console.log("REFRESH REQ:",req);
    //         this.refreshTokenInProgress = true;

    //         // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
    //         this.refreshTokenSubject.next(null);
            
    //         // return this.refreshAccessToken().pipe(
    //         //   switchMap((success: boolean) => {
    //         return this.auth.refreshAccessToken().pipe(
    //           switchMap((r_token) => {
    //             console.log("REFRESH INTERCEPTOR CALL", r_token);
    //             this.refreshTokenSubject.next(r_token['token']);
    //             return next.handle(<any> this.addAuthenticationToken(req));
    //           }),
    //           // When the call to refreshToken completes we reset the refreshTokenInProgress to false
    //           // for the next time the token needs to be refreshed
    //           finalize(() => this.refreshTokenInProgress = false)
    //         );
    //       }
    //     } else {
    //       // If error status is different than 401 return error
    //       return <any>throwError(error);
    //     }
    //   })
    // );
  }

  // private refreshAccessToken(): Observable<any> {
  //   console.log("REFRESH TOKEN CALLED");
  //   var _refreshToken;
  //   this.auth.refreshAccessToken().subscribe(
  //       (rToken) => {
  //           console.log("Refreshed TOKEN",rToken);
  //           localStorage.setItem('refresh_token', rToken['access_token']);
  //           _refreshToken = rToken;
  //       }
  //   )
  //   return of(_refreshToken);
  // }

  private addAuthenticationToken(req: HttpRequest<any>): HttpRequest<any> {
    // If we do not have a token yet then we should not set the header.
    // Here we could first retrieve the token from where we store it.

    // const token = localStorage.getItem('token');
    const token = this.localStorage.getData('accessToken');
    // console.log("ORIGINAL TOKEN", token);
    if (!token) {
      return req;
    }
    // If you are calling an outside domain then do not add the token.
    // if (!request.url.match(/www.mydomain.com\//)) {
    //   return request;
    // }


    // console.log(this.apiUrl + request.url);
    // const url = this.apiUrl + request.url;

    // const q = {url: url, urlWithParams: url};
    // request = Object.assign(request, q);

    // console.log("updated",request);



    return req.clone({
      headers: req.headers.set(this.AUTH_HEADER, "Bearer " + token)
    });
  }
}