import { Component, OnInit, Inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material';
import { Dashboard, Country, Client } from '../../../../shared/models/api.model';
import { ClientService } from '../../../../services/client.service';
import { Observable } from 'rxjs';
import { AddClientCountryComponent } from '../add-client-country/add-client-country.component';

@Component({
  selector: 'app-client-countries-list',
  templateUrl: './client-countries-list.component.html',
  styleUrls: ['./client-countries-list.component.css']
})
export class ClientCountriesListComponent implements OnInit {

  pageTitle = "Client Countries";
  hide: any = {};
  // client$: Observable<Client>;  
  client$: any = [];  
  // dashboard: any = [];

  public error = [];

  constructor(
    private title: Title,
    private clientService: ClientService,
    private router: Router,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<ClientCountriesListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // this.dashboard$ = data.dashboard
    this.client$ = data.client
    console.log("curClient", data.client);
  }

  // postDetail(dashboard: Dashboard) {
  //   console.log(dashboard);
  //   // this.router.navigate(['dashboards', dashboard.slug]);
  // }

  ngOnInit() {
    this.title.setTitle(this.pageTitle);
    // this.getDashboards();
  }

  addClientCountry() {
    // console.log("clicked");
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'menu-reveal';
    dialogConfig.data = { client: this.client$ };
    const addUser = this.dialog.open(AddClientCountryComponent, dialogConfig);
    addUser.afterClosed().subscribe(
      () => {
        console.log("after country add", this.client$);
        this.clientService.getClient(this.client$['slug']).subscribe(
          (res) => {
            this.client$ = res['data'];
            console.log("after new country",this.client$);
          }
        )
      }
    )
  }

  removeClientCountry(country) {
    let data = {
      id: this.client$['id'],
      country: country.id
    }
    // console.log(data);

    this.clientService.removeClientCountry(data).subscribe(
      (data) => {
        this.handleResponse(data);
        if(data) {
          this.hide[country.id] = !this.hide[country.id]
        }
      },
      error => this.handleError(error)
    )
  }

  handleResponse(data) {
    console.log(data);
    // this.router.navigateByUrl('/admin/dashboards');
  }

  handleError(error) {
    this.error = error.error.errors;
  }

  closeBtn(): void{
    this.dialogRef.close();
  }

}
