import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { MatDialogRef } from '@angular/material';
import { ActivityMonitorService } from '../../services/activity-monitor.service';
import { LocalStoreManager } from '../../services/local-store-manager.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-session-timeout',
  templateUrl: './session-timeout.component.html',
  styleUrls: ['./session-timeout.component.css']
})
export class SessionTimeoutComponent implements OnInit, OnDestroy {

  pageTitle = "Session Timeout";

  status: any;
  getAMStatusSubscription: Subscription;

  constructor(
    private title: Title,
    private localStorage: LocalStoreManager,
    public dialogRef: MatDialogRef<SessionTimeoutComponent>,
    private amService: ActivityMonitorService
  ) { }

  ngOnInit() {
    this.title.setTitle(this.pageTitle);
    this.getAMStatusSubscription = this.amService.getAMData$.subscribe(status => {
      // console.log('inside message', status);
      this.status = status;
    });
  }

  endSession() {
    this.dialogRef.close('end session');
  }

  extendSession() {
    this.dialogRef.close('extend session');
  }

  ngOnDestroy() {
    this.getAMStatusSubscription.unsubscribe();
  }

}
