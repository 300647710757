import { NgModule, ModuleWithProviders } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AuthInterceptor } from '../interceptors/auth-interceptor';
import { SafeUrlPipe } from '../shared/pipes/safeurl.pipe';
import { SlugifyPipe } from '../shared/pipes/slugify.pipe';
import { DateFormatPipe } from '../shared/pipes/date-format.pipe';
import { LoaderInterceptor } from '../interceptors/loader-interceptor';
import { LoaderComponent } from '../components/loader/loader.component';
import { SessionTimeoutComponent } from '../components/session-timeout/session-timeout.component';
import { MaterialModule } from '../material.module';
import { DashboardFilterComponent } from '../components/dashboard-filter/dashboard-filter.component';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule
  ],
  declarations: [
    SafeUrlPipe,
    SlugifyPipe,
    DateFormatPipe,
    LoaderComponent,
    SessionTimeoutComponent,
    DashboardFilterComponent
  ],
  exports: [
    HttpClientModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SafeUrlPipe,
    SlugifyPipe,
    DateFormatPipe,
    LoaderComponent,
    // SessionTimeoutComponent
  ],
  entryComponents: [
    SessionTimeoutComponent,
    DashboardFilterComponent
  ]
})
export class CoreModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: CoreModule,
      providers: [
        Title,
        DatePipe,
        SafeUrlPipe,
        SlugifyPipe,
        DateFormatPipe,
        LoaderComponent,
        // ApiService,
        // UtilsService,
        // FilterSortService
        { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
      ]
    };
  }
}
