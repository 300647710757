import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { DashboardService } from '../../../../services/dashboard.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatSlideToggleChange, MatSlideToggle } from '@angular/material';
import { Observable } from 'rxjs';
import { Dashboard, User } from 'src/app/shared/models/api.model';
import { DashboardUsersListComponent } from '../dashboard-users-list/dashboard-users-list.component';
import { EditDashboardComponent } from '../edit-dashboard/edit-dashboard.component';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-admin-dashboard-detail',
  templateUrl: './admin-dashboard-detail.component.html',
  styleUrls: ['./admin-dashboard-detail.component.css']
})
export class AdminDashboardDetailComponent implements OnInit {

  @Output()
  change: EventEmitter<MatSlideToggleChange>

  pageTitle = "Dashboard Details";
  // dashboard$: Observable<Dashboard>;
  dashboard$: any = [];
  // users$: Observable<User>;
  users$: any = [];
  clients: any = [];
  countries: any = [];

  client;
  country;
  year;
  month;
  status: boolean;

  months: any = [
    {id:1, name:'January'},
    {id:2, name:'February'},
    {id:3, name:'March'},
    {id:4, name:'April'},
    {id:5, name:'May'},
    {id:6, name:'June'},
    {id:7, name:'July'},
    {id:8, name:'August'},
    {id:9, name:'September'},
    {id:10, name:'October'},
    {id:11, name:'November'},
    {id:12, name:'December'}
  ];

  public error = [];

  constructor(
    private title: Title,
    private dashboardService: DashboardService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<AdminDashboardDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.log(data);
    this.dashboard$ = data.dashboard;
    this.client = data.dashboard.client.name;
    this.country = data.dashboard.country.name;
    this.year = data.dashboard.year;
    this.month = data.dashboard.month;
    (data.dashboard.status === "publish") ? this.status = true : this.status = false;
    console.log(this.status);
  }

  ngOnInit() {
    this.title.setTitle(this.pageTitle);
  }

  getMonth(month: number){
    for(let sMonth of this.months) {
      if(sMonth['id'] === month) {
        return sMonth['name'];
      }
    }
  }

  editDashboard(dashboard) {
    // console.log(dashboard);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'menu-reveal';
    dialogConfig.data = { dashboard: dashboard };
    const dashboardDetail = this.dialog.open(EditDashboardComponent, dialogConfig);
    dashboardDetail.afterClosed().subscribe(
      () => {
        let slug = localStorage.getItem("curItem");
        this.dashboardService.getDashboard(slug).subscribe(
          (res) => {
            this.dashboard$ = res['data'];
            this.client = res['data'].client.name;
            this.country = res['data'].country.name;
            this.year = res['data'].year;
            this.month = res['data'].month;
            console.log("new dashboard details",this.dashboard$);
            localStorage.removeItem("curItem");
          }
        )
      }
    );
  }

  editDashboardUsers() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'menu-reveal';
    dialogConfig.data = { dashboard: this.dashboard$ };
    const dashboardUsers = this.dialog.open(DashboardUsersListComponent, dialogConfig);
    dashboardUsers.afterClosed().subscribe(
      () => {
        this.dashboardService.getDashboard(this.dashboard$['slug']).subscribe(
          (res) => {
            this.dashboard$ = res['data'];
            console.log("new user",this.dashboard$);
          }
        )
      }
    )
  }

  toggleDashboardStatus(ob: MatSlideToggleChange) {
    // console.log(ob.checked);
    let data = {
      id: this.dashboard$['id'],
      status: ob.checked
    }

    // console.log(data);

    this.dashboardService.dashboardStatus(data).subscribe(
      (data) => {
        this.handleResponse(data);
      },
      error => this.handleError(error)
    )
  }

  handleResponse(data) {
    console.log(data);
    // this.router.navigateByUrl('/admin/dashboards');
  }

  handleError(error) {
    this.error = error.error.errors;
  }

  closeBtn(){
    this.dialogRef.close();
  }

}
