import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { User } from '../shared/models/api.model';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpClient
  ) { }

  //USER

  // Get users
  getUsers(): Observable<User[]>{
    return this.http.get<User[]>(`/users`);
  }

  // Get single user
  getUser(id): Observable<User>{
    return this.http.get<User>(`/user/`+id);
  }

  login(data){
    return this.http.post('/login', data);
  }

  register(data){
    return this.http.post('/register', data);
  }

  passwordReset(data) {
    return this.http.post('/password-reset', data);
  }

  checkPasswordToken(token) {
    return this.http.get('/password/verify-token/'+token);
  }

  changePassword(data) {
    return this.http.post('/reset-password', data);
  }

  generatePasswordLink(data) {
    return this.http.post('/password-reset', data);
  }

  // Edit User
  editUser(user: User) {
    return this.http.put('/user', user);
  }

  // Add User Dashboard(s)
  addUserDashboard(data){
    return this.http.post('/user/add-dashboards', data);
  }

  // Remove User Dashboard
  removeUserDashboard(data){
    return this.http.post('/user/remove-dashboards', data);
  }

  // loadUsers(filter: string, sort: string, order: string, page: number): Observable<User> {
  //   const requestUrl = `/users?filter=${filter}&sort=${sort}&order=${order}&page=${page + 1}`;
  //   return this.http.get<User>(requestUrl);
  // }

  // findUsers(userId:number, filter = '', sortOrder = 'asc', pageNumber = 0, pageSize = 3): Observable<User[]> {
  //   return this.http.get('/user/'+userId, {
  //     params: new HttpParams()
  //       .set('userId', userId.toString())
  //       .set('filter', filter)
  //       .set('sortOrder', sortOrder)
  //       .set('pageNumber', pageNumber.toString())
  //       .set('pageSize', pageSize.toString())
  //   }).pipe(
  //     map(res =>  res["payload"])
  //   );
  // }

  loadUsers(filter, sortBy, sortOrder, pageNumber, pageSize): Observable<User[]> {
    if(sortBy == '') sortBy = "id";
    return this.http.get('/users-list', {
      params: new HttpParams()
        .set('q', filter)
        .set('sortBy', sortBy)
        .set('orderBy', sortOrder)
        .set('page', pageNumber + 1)
        .set('pageSize', pageSize.toString())
    })
    .pipe(
      map((users:User[]) => {
        // console.log (users);
        return users;
      })
    );
  }

  allUserFullNames() {
    return this.http.get('/users-fullnames');
  }

  testUsers(filter, sortOrder, pageNumber, pageSize) {
    return this.http.get(`/users-list`, {
      params: new HttpParams()
        .set('filter', filter)
        .set('sortOrder', sortOrder)
        .set('pageNumber', pageNumber.toString())
        .set('pageSize', pageSize.toString())
    }).pipe(
      map(users => {return users["data"]
      })
    )
  }


}
