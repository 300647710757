import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';

import { UserService } from '../../services/user.service';
import { TokenService } from '../../services/token.service';
import { AuthService } from '../../services/auth.service';
import { LocalStoreManager } from '../../services/local-store-manager.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  pageTitle = 'Tomorrow TTH | Portal';
  userID;

  fullName;

  constructor(
    private localStorage: LocalStoreManager,
    private title: Title,
    private router: Router,
    private token: TokenService,
    private auth: AuthService,
    private userService: UserService,
    private dialogRef: MatDialog
  ) {
    // this.redirect();
    // setTimeout(() => this.userID = this.localStorage.getData('id'),500);
    // this.userID = this.localStorage.getData('id');
    // this.auth.getUserId().then((value) => {
    //   console.log("PROMISE",value);
    //   this.userID = value;
    //   console.log("ID", this.userID);
    //   this.redirect();
    // });
  }

  ngOnInit() {
    this.title.setTitle(this.pageTitle);
    // const startTime = Date.now();
    this.auth.getUserId()
      .then((value) => {
        // console.log("PROMISE",value);
        // console.log(this.localStorage.getData('fn'));
        this.userID = value
        this.fullName = this.auth.getFullName();
      })
      // .then(()=> {
      //   const tt = Date.now() - startTime;
      //   console.log("TIME",tt);
      // })
      .then(()=>this.redirect());
    
    // setTimeout(() => this.userID = this.localStorage.getData('id'),500);
    // console.log(this.auth.getCurrentUser());
    // this.redirect();
    // this.title.setTitle(this.pageTitle);
    // console.log("USER ID",this.userID);
  }

  // getFullName() {
  //   this.fullName = `${this.localStorage.getData('fn')} ${this.localStorage.getData('ln')}`;
  // }

  redirect() {
    // console.log("redirect", this.userID, this.fullName);
    if(!this.userID) {
      return this.router.navigate(['login']);
    } else {
      this.userService.getUser(this.userID).subscribe(
        (res: any) => {
          let curUser = res['data'];
          let name = curUser['name'];
          let role = curUser['roles'][0]['slug'];
          // console.log(name, role);
          if(name === this.fullName && (role === "admin" || role === "super-admin")) {
            this.router.navigate(['admin']);
          } else {
            if(name === this.fullName && role === "basic") {
              this.router.navigate(['dashboards']);
            } else {
              this.dialogRef.closeAll();
              // this.token.destroy();
              this.localStorage.clearAllStorage();
              this.auth.changeAuthStatus(false);
              // localStorage.removeItem('c_user');
              this.router.navigate(['login']);
            }
          }
        }
      )
    }
  }

}
