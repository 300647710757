import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../../core/core.module';

// import { PublicRoutingModule } from './public-routing.module';

import { DashboardsComponent } from './dashboards/dashboards.component';
import { DashboardDetailComponent } from './dashboards/dashboard-detail/dashboard-detail.component';
import { MaterialModule } from 'src/app/material.module';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    MaterialModule,
    // PublicRoutingModule
  ],
  declarations: [
    DashboardsComponent,
    DashboardDetailComponent,
  ]
})
export class PublicModule { }
